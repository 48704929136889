:root {
    --h1: 30px;
    --h2: 26px;
    --h3: 18px;
    --h4: 16px;
    --h5: 14px;
    --h6: 12px;
    --h7: 10px;
    --form-padding: 20px;
    --form-padding-540: 14px;
}