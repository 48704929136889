// .payment-hr {
// 	width: 100px;
// 	height: 1px !important;
// 	background-color: var(--text-midgray);
// 	margin: 9px 0px !important;
// }

// .vat-button .bg-buttonSuccess {
// 	&:disabled {
// 		pointer-events: none;
// 		opacity: 0.5;
// 		cursor: default;
// 	}
// }

// .clickable {
// 	&:hover {
// 		cursor: pointer;
// 	}
// }


.booking-payment-summary-wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 285px;
	height: 285px;
	padding: 10px;
	border-radius: 10px;

	>* {
		margin-bottom: 10px;
	}

	p {
		margin: 0px 0px;
	}

	.bp-title-row {
		display: grid;
		grid-template-columns: 1fr 100px;
		font-size: 14px;
		align-items: center;

		.bp-addvat-button {
			display: flex;
			position: relative;
			height: 25px;
			align-items: center;
			justify-content: center;
			gap: 10px;
			color: white;
			border-radius: 10px;
			background-color: var(--primary-color);

			&:hover {
				cursor: pointer;
			}
		}

		&.title-bal {
			grid-template-columns: 1fr 130px;
		}

		.bp-balance {
			@extend .bp-addvat-button;
			background-color: var(--highlight-orange);

			&:hover {
				cursor: copy;
			}
		}
	}

	.bp-cost-block {
		display: grid;
		grid-template-columns: 1fr 30px 70px;
		font-size: 12px;
		color: var(--text-midgray);
		margin-bottom: 10px;

		.line {
			border-bottom: 1px solid var(--text-midgray);
			margin-bottom: 2px;
		}

		.temp-id {
			background-color: #3498db;
			color: white;
			padding-right: 5px;
			border-radius: 2px;

			&:hover {
				cursor: pointer;
				background-color: #3498dbaa;
			}
		}
	}

	.bp-mid-status-block {
		width: 265px;
		height: 30px;
		color: white;
		border-radius: 10px;
		padding: 5px 10px;
		align-items: center;

		&.bp-payment {
			display: grid;
			grid-template-columns: 1fr 30px 80px;
			font-size: 12px;
			background-color: var(--payment);
		}

		&.bp-vat {
			display: grid;
			grid-template-columns: 1fr 30px;
			font-size: 12px;
			background-color: var(--primary-color);
		}

		&:hover {
			cursor: pointer;
		}
	}

	.bp-payment-block {
		display: flex;
		flex-direction: column;


		.option-block {
			display: flex;
			height: 30px;
			gap: 5px;
			margin-bottom: 10px;

			.bp-options {
				display: flex;
				flex: 1;
				gap: 10px;

				.bp-option-label {
					display: flex;
					font-size: 12px;
					width: 100%;
					border-radius: 5px;
					align-items: center;
					justify-content: center;
					text-transform: capitalize;

					>i {
						margin-right: 10px;
					}

					&.errorlogger {
						border: 1px solid red;
						box-sizing: border-box;
					}
				}

				.bp-option-checkbox[type=radio] {
					display: none;
				}

				.bp-option-checkbox:checked+.bp-option-label {
					background: #3498DB;
					color: var(--background-color);

					i {
						color: var(--background-color);
					}
				}
			}

		}

		.fields-block {
			width: 100%;

			.payment-field-wrapper {
				display: grid;
				font-size: 14px;
				grid-template-columns: 90px 20px 135px;
				grid-column-gap: 10px;
				margin-bottom: 10px;

				.payment-date-switch {
					font-size: 12px;
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					box-sizing: border-box;
					border: 2px solid var(--afternoon);
					border-radius: 2px;

					&:hover {
						color: white;
						background: var(--afternoon);
						cursor: pointer;
					}
				}

				>input {
					background: transparent;
					width: 100%;
					padding-right: 5px;
					text-align: right;
					border-bottom: 1px solid var(--highlight-grey);
				}

				input[type=number]::-webkit-inner-spin-button,
				input[type=number]::-webkit-outer-spin-button {
					-webkit-appearance: none;
				}

				&.hide {
					display: none;
				}

				.errorlogger {
					border-color: red;
				}
			}
		}


	}

	.bp-button-block {
		display: flex;
		height: 30px;
		width: 265px;
		font-size: 12px;
		justify-content: space-between;
		gap: 15px;
		position: absolute;
		bottom: 0px;
		color: white;

		.bp-buttons {
			position: relative;
			display: flex;
			flex: auto;
			border-radius: 10px;
			justify-content: center;
			align-items: center;
			gap: 15px;
			background-color: var(--payment);
			box-shadow: -2px -2px 4px var(--shadow-color-light),
				2px 2px 4px var(--shadow-color-dark-rgba);

			&:hover {
				cursor: pointer;

			}

			&.bp-disabled {
				background-color: var(--text-light-grey);
				box-shadow: none;

				&:hover {
					cursor: not-allowed;
				}
			}

			&.cancel {
				background-color: transparent;
				color: var(--text-dark-grey);
			}

			&.comp {
				background-color: var(--primary-color);
			}

			&.reopen {
				background-color: var(--afternoon);
			}

		}

	}

	.bp-success-block {
		display: flex;
		flex-direction: column;
		height: 260px;
		align-items: center;
		justify-content: center;
		gap: 20px;

		>img {
			width: 30px;
			height: 30px;
		}
	}

}

.bp-amounts {
	text-align: right;

	&.focus {
		color: var(--text-dark-grey);
	}
}