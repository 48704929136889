@import '../../assets/css/color-variable.scss';

// .grid-item {
//     height: 40px;
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;
//     width: 150px;
//     cursor: pointer;

//     .item-image-wrapper {
//         width: 32px;
//         height: 32px;
//         border-radius: 50px;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         padding: 5px;

//         &.selected {
//             background: #F09E9B 0% 0% no-repeat padding-box;
//         }

//         &.veg,
//         &.bread,
//         &.dal,
//         &.staple {
//             background: #A9CE7B 0% 0% no-repeat padding-box;
//         }
//     }

//     .item-name-wrapper {
//         margin-left: 10px;
//         font-size: 12px;
//     }
// }

// .menu-search-button{
//     // flex justify-between mt-10px
//     display: flex;
//     justify-content: space-between;
//     margin-top: 10px;

//     .search-checkbox{
//         display:flex; 
//         gap: 15px;
//     }
// }

// @media only screen and (max-width: 630px) {
//     .menu-search-button{
//         flex-direction: column;

//         .search-checkbox{
//             flex-direction: column;
//             margin-bottom: 15px;
//         }
//     }
// }

.menuset-container {
	margin-top: 15px;
	padding: 15px;
	border-radius: 5px;

	.menuset-button {
		width: 130px;
		height: 30px;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		cursor: pointer;
	}
}