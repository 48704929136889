@import '../../assets/css/color-variable.scss';
$color-1: var(--shadow-color-dark-rgba);

/* for calendar controller */


.month-wrapper {
	font-size: 26px;
	color: #474747;
	text-align: center;
}

.calendar-controller-wrapper {
	min-height: 30px;
	width: 100%;
	margin-top: 10px;
	display: flex;
	flex-direction: row;

	.date-controller {
		display: flex;
		flex: 1.2;
		justify-content: flex-end;

		.calendar-controller-container {
			height: 30px;
			display: flex;
			flex-direction: row;
			border-radius: 50px;
			width: 125px;

			.week-change-btn {
				height: 30px;
				width: 24px;
				display: flex;
				justify-content: center;
				align-items: center;

				cursor: pointer;

				i {
					color: #474747;
				}

				&:hover {
					background: linear-gradient(106deg, #fcfcfc, #fafaff);

					i {
						color: #73528c;
					}
				}

				&.prev {
					border-radius: 50px 0px 0px 50px;
				}

				&.next {
					border-radius: 0px 50px 50px 0px;
				}
			}

			.today-btn {
				height: 30px;
				width: 74px;
				font-size: 14px;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;

				&:hover {
					background: linear-gradient(106deg, #fcfcfc, #fafaff);
					color: #73528c;
				}
			}

			.btn-divider {
				width: 2px;
				height: 20px;
				background: linear-gradient(175deg,
						#f7f7fa 0%,
						#f4f4f8 100%);
				box-shadow: -0.4px -0.2px 0.5px #ffffff,
					0.8px 0.7px 0.7px rgba($color: #1c1222, $alpha: 0.2);
				border-radius: 10px;
				margin-top: 2px;
			}
		}
	}

	.calendar-controller {
		height: 30px;
		display: flex;
		flex-direction: row;
		align-items: center;
		flex: 1;
		justify-content: flex-end;
		padding-right: 10px;
		gap: 7px;

		.calendar-button-container {
			position: relative;

			.nep-dropdown-button {
				background: var(--afternoon);
				color: white;
			}

			.dropdown-wrapper {
				padding-top: 10px;
				display: none;
				position: absolute;

				&.calendar {
					right: 0;
				}

				/*  css for venue filter */
				.dropdown {
					min-width: 160px;
					z-index: 1;
					font-size: 12px;
					padding: 10px;

				}
			}

			&:hover {
				.dropdown-wrapper {
					display: block;
				}
			}


		}
	}


}

@media only screen and (max-width: 945px) {

	.calendar-controller-wrapper {
		display: flex;
		flex-direction: column;
		row-gap: 20px;

		.date-controller {
			justify-content: center;
		}

		.calendar-controller {
			justify-content: center;
			padding: 0px;
		}

	}
}

@media only screen and (max-width: 380px) {
	.calendar-controller-wrapper {
		.calendar-controller {
			.calendar-button-container {
				.dropdown-wrapper {
					left: -110px;
					width: fit-content;
					max-width: 95vw;
				}
			}
		}

	}
}