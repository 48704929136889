.unselected{
    fill:#9D9D9D;
    fill-rule:evenodd;
}

.selected{
    fill: #FAFAFF;
}
.icon-size{
    width:20px;
    height:20px;
}
