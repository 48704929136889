@import '../../assets/css/size-variable.scss';
@import '../../assets/css/color-variable.scss';

$color-1: var(--shadow-color-dark-rgba);

.login-form-content {
	width: 650px;
	height: 438px;
	display: flex;
	flex-wrap: nowrap;
	border-radius: 10px;
}

.sign-in-content {
	display: flex;
	width: 325px;
	height: 438px;
	border-radius: 10px 0px 0px 10px;
	flex-direction: column;
	align-items: center;

	.signin-controller {
		width: 100%;
		padding: 0px var(--form-padding);
		display: flex;
		justify-content: space-between;
		margin-top: 35px;

		.forgot-password {
			color: var(--text-midgray);
			cursor: pointer;
			font-size: var(--h6);

			&:hover {
				color: var(--primary-color);
			}
		}

		.login-button {
			width: 150px;
			height: 40px;
			font-size: 12px;
			border-radius: 30px;
			box-shadow: -2px -2px 4px var(--signin-button-shadow-light), 2px 2px 4px var(--primary-color);
			margin-top: 20px;
		}
	}
}

.sign-up-content {
	display: flex;
	width: 325px;
	height: 438px;
	background-color: var(--primary-color);
	flex-direction: column;
	align-items: center;
	border-radius: 0px 10px 10px 00px;

	.cater-me-logo {
		height: 100px;
		margin-bottom: 10px;
	}

	.signup-message {
		font-size: 24px;
		color: var(--background-color);
	}

	.signup-button {
		width: 100px;
		height: 30px;
		font-size: var(--h5);
		border-radius: 30px;
		background-color: var(--primary-color);
		box-shadow: -2px -2px 4px var(--shadow-color-light), 2px 2px 4px $color-1;
		margin-top: 30px;
		color: var(--background-color);
	}
}

.signin-form-title {
	font-size: var(--h2);
	color: var(--text-dark-grey);
	margin-top: 35px;
	margin-bottom: 40px;

	&.signup {
		color: var(--background-color);
		margin-bottom: 40px;

	}
}

@media only screen and (max-width: 540px) {

	.login-form-content {
		width: 325px;
		height: 628px;
		flex-direction: column;
	}

	.signin-form-title {
		&.signup {
			font-size: 14px;
			color: var(--background-color);
			margin-top: 30px;
			margin-bottom: 12px;
		}
	}

	.sign-up-content {
		height: 200px;
		border-radius: 0px 0px 10px 10px;

		.cater-me-logo {
			height: 40px;
			margin-bottom: 10px;
		}

		.signup-message {
			font-size: 16px;
			color: var(--background-color);
		}

		.signup-button {
			margin-top: 20px;
		}
	}

	.sign-in-content {
		.signin-controller {
			padding: 0px var(--form-padding-540);
		}
	}
}