.tran-pay-wrapper {
	padding: 5px 15px;

	&.columed {
		display: flex;
		gap: 20px;
	}

	.tran-pay-items {
		display: flex;
		gap: 5px;
		flex-wrap: wrap;
	}

	.tran-pay-title {
		font-size: 14px;
		text-decoration: underline;
	}
}



.transfer-payment-block {
	display: flex;
	flex: 1;
	min-width: 150px;
	max-width: 280px;
	flex-direction: column;
	border-radius: 8px;
	padding: 10px;
	text-transform: capitalize;
	font-size: 12px;

	&.selectTrans {
		border-bottom: 5px solid var(--afternoon);
	}

	&:hover {
		cursor: pointer;
		color: white;
		font-weight: bold;
		background: var(--afternoon);
	}
}