@import "../../../assets/css/color-variable.scss";

.date-event-detail {
	width: 165px;
	height: 420px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	overflow: hidden; //Rajbin New

	.date-divider {
		height: 2px !important;
		// margin: 10px 0px;
		margin-top: 5px !important;
		margin-bottom: 10px !important;
		margin-right: 4px;
		margin-left: 5px;
		width: 136px;
		background-color: var(--text-midgray);

		text-align: center;

		&.selected {
			height: 4px;
			background: var(--confirmed);
		}
	}

	.cal-day-label {
		flex-direction: column;
		display: flex;
		width: 100%;

		.cal-day-label-date-en {
			color: var(--text-dark-grey);
			font-size: 14px;
			margin-top: 10px;
		}

		.cal-day-label-date-np {
			color: var(--text-midgray);
			font-size: 12px;
			margin-top: 4px;
		}

		.tithi {
			height: 14px;
			color: white;
			font-size: 10px;
			margin: 0px 4px;
			border-radius: 2px;

			&.tithi-highlight {
				background-color: var(--primary-color);
			}
		}

		.cal-day-label-day {
			color: var(--text-dark-grey);
			font-size: 12px;
			margin-top: 4px;

			&.selected {
				// height: 4px;
				color: var(--confirmed);
			}
		}

		&.selected {
			//Rajbin New
			box-sizing: border-box;
			border-radius: 0px;
			// border: 2px solid var(--confirmed);
			background-color: var(--venue-3);

			.cal-day-label-day {
				color: var(--text-dark-grey);
			}

			.cal-day-label-date-np {
				color: var(--text-dark-grey);
			}

			.tithi {
				color: white;
			}
		}
	}

	.event-container {
		width: 100%;
		min-height: 280px;
		padding-bottom: 5px;
		overflow: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: 15px;
	}

	.event-container::-webkit-scrollbar {
		width: 0px;
	}
}