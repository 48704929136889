@import "../../../assets/css/color-variable.scss";

.navbar-container>ul {
	height: 45px;
	list-style-type: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
	background: linear-gradient(90deg,
			var(--primary-color) 0%,
			var(--secondary-color) 100%) 0% 0% no-repeat padding-box;
}

.right-nav {
	float: right;
	cursor: pointer;
	margin-right: 8px;
}

.left-nav {
	float: left;
	display: flex;
	justify-content: center;
	align-content: center;
}

.left-nav>img {
	padding: 5px 5px 5px 8px;
}

.cater-me-logo {
	margin-top: 2.5px;
	margin-left: 5px;
	height: 35px;
	cursor: pointer;
}

.cater-me-name {
	margin-left: 20px;
	text-transform: uppercase;
	cursor: pointer;
}

li i,
li .search-box {
	display: block;
	color: var(--background-color);
	text-align: center;
	padding: 14px 10px;
	text-decoration: none;
}

li .search-box {
	margin: 25px 0px;
}

li .search-box input {
	cursor: pointer;
}

li label {
	font-size: 30px;
	color: var(--background-color);
	text-align: center;
	padding-top: 2px;
}

.user-dp {
	width: 30px;
	height: 30px;
	box-shadow: -2px -2px 4px #b792d3b3;
	border-radius: 50px;
	margin-top: 8px;
}

//--------------------------------------for notification----------------------------------

.notification-list-wrapper {
	padding-top: 2px;
	min-width: 325px;
	display: none;
	right: 50px;
	position: absolute;
}

.notification-content {
	border-radius: 5px;
	height: 325px;
	box-sizing: border-box;
}

.notification-container:hover .notification-list-wrapper {
	display: block;
}

.notification-header {
	// background-color: #523563;
	margin: 5px 10px 0px 10px;
	padding: 10px 3px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 1px var(--text-dark-grey) solid;
	box-sizing: border-box;
	width: 300px;
}

.notification-title-label,
.notification-read-label {
	font-size: 12px;
	color: var(--text-dark-grey);
}

.notification-read-label {
	cursor: pointer;
}

.show-notification {
	max-height: 270px;
	overflow: scroll;
	overflow-x: hidden;
	margin-top: 5px;
	padding: 10px;
}

.refresh-container {
	cursor: pointer;

	i {
		padding: 5px 7px !important;
		color: var(--text-dark-grey);
		font-size: 12px;
	}
}

// -----------------------------------------for profile dropdown------------------------------------------------
.settings-wrapper {
	display: none;
	right: 0;
	position: absolute;
	min-width: 120px;
	padding-top: 7px;
}

.settings {
	background: linear-gradient(90deg,
			var(--primary-color) 0%,
			var(--secondary-color) 100%) 0% 0% no-repeat padding-box;
	box-shadow: 2px 2px 4px #1c122233;
	border-radius: 0px 0px 0px 5px;
	opacity: 1;
}

.settings {
	ul {
		list-style-type: none;
		box-sizing: border-box;
	}
}

.settings {
	li {
		font-size: 12px;
		color: var(--background-color);
		padding: 8px 16px;
		text-decoration: none;
		//display: block;
		text-align: right;
		//width: 100%;
	}
}

.settings {
	li:hover {
		background-color: #523563;
	}
}

.user-settings {
	i {
		color: white;
	}
}

.user-settings:hover .settings-wrapper {
	display: block;
}


.notifyIcon {
	display: flex;
	position: relative;

	.notifyCount {
		position: absolute;
		display: flex;
		top: 1px;
		left: 20px;
		border: 1px solid white;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		background-color: red;
		justify-content: center;
		align-items: center;
	}

	.unseen-count {
		font-size: 12px;
	}
}