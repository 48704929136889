.venue-activation-wrapper {
    height: 100px;
    width: 200px;
    background-image: linear-gradient(107deg, #F7F7FA, #F4F4F8);
    box-shadow: -2px -2px 4px #FFF, 2px 2px 4px rgba($color: #1c1222, $alpha: 0.2);
    margin-right: 10px;
    display: flex;
    flex-direction: row;

    &:last-child {
        margin-right: 0px;
    }

    .venue-color-container {
        width: 30px;
        height: 100%;
        margin-left: 10px;

        .venue-color-wrapper {
            width: 30px;
            height: 34px;
            background-image: linear-gradient(107deg, #F7F7FA, #F4F4F8);
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: inset 2px 2px 4px rgba($color: #1c1222, $alpha: 0.1), inset -2px -2px 4px #FFF;
            border-radius: 0px 0px 100px 100px;


            .venue-color {
                width: 26px;
                height: 32px;
                border-radius: 0px 0px 100px 100px;
            }
        }
    }

    .venue-information-container {
        width: 100%;
        margin-left: 7px;

        .venue-details {
            font-size: 12px;
            color: #9D9D9D;
            text-align: left;

            &:first-child {
                margin-top: 5px;
            }

            &.first {
                font-size: 14px;
                color: #474747;
            }

            &.status {
                margin-top: 5px;

                .venue-permission-container {
                    // width:100%;
                    width: 120px;
                    height: 30px;
                    position: relative;
                    // background-color: red;
                }

                .venue-permission-content-label {
                    position: absolute;
                    background-color: #D35A47;
                    line-height: 30px;
                    height: 30px;
                    width: 120px;
                    color: white;
                    text-align: center;
                    cursor: pointer;
                    // box-shadow: inset 2px 2px 4px rgba($color: #1c1222, $alpha: 0.1), inset -2px -2px 4px #52BE7F;

                    &.disabled {
                        opacity: 0.6;
                        cursor: auto
                    }

                }

                .venue-permission-checkbox[type=checkbox] {
                    display: none;
                }

                .venue-permission-checkbox:checked+.venue-permission-content-label {

                    background-color: #52BE7F;

                }
            }
        }
    }

}