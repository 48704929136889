.ana-summary-container {
	background-color: #f8f8fc;
	box-shadow: inset 5px 5px 8px 0px #ffffff, 6px 4px 8px 0px #e7e7e7;
	// background-color: #f1f1fc;
	flex: 1 0 200px;
	font-size: 1em;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 16px;
	padding: 16px;
	border-radius: 8px;

	.summary-icon-container {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #523563;
		color: #f8f8fc;
		font-size: 14px;
	}

	.summary-details {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 4px;

		.summary-main-value {
			font-size: 2em;
			font-weight: 800;
			color: #272727;

			span {
				font-size: 12px;
			}
		}

		.summary-main-label {
			font-size: 1.1em;
			color: #9d9d9d;
		}

		.summary-change-data {
			font-size: 1em;
			font-weight: 800;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 4px;

			i {
				margin-bottom: 2px;
			}
		}
	}
}