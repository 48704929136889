/* css for payment method selection */

.payment-option-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.payment-option-container {
	height: 52px;
	width: 285px;
	gap: 15px;
	display: flex;
}


.payment-option-content {
	font-size: 12px;
	height: 40px;
	width: 135px;
	box-shadow: -2px -2px 4px var(--shadow-color-light), 2px 2px 4px var(--shadow-color-dark-rgba);
	background-color: var(--gradient-background-two);
	border-radius: 5px;

	*:hover {
		cursor: pointer;
	}
}

.payment-option-icon {
	display: flex;
	justify-content: center;
	align-items: center;
}

.payment-option-label {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 10px 10px 5px;
	border-radius: 5px;

}

.payment-option-icon {
	width: 30px;
	left: 10px;
	height: 30px;
}

.payment-option-checkbox[type=radio] {
	display: none;
}

.payment-option-checkbox:checked+.payment-option-label {
	background-color: #3498DB;
	color: var(--background-color);

	i {
		color: var(--background-color);
	}
}