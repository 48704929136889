$label-size: 12px;
$text-size: 12px;
$s-text-size: 11px;

.print-page-wrapper {
	display: flex;
	width: 100vw;
	height: 100vh;
	overflow-y: auto;
	flex-direction: column;
	gap: 20px;
	margin-bottom: 40px;
	align-items: center;
	padding: 40px 0;


	.print-page-action-container {
		display: flex;
		width: 800px;
		margin-top: 40px;
		margin-bottom: 20px;

		.print-button {
			display: flex;
			width: 100px;
			height: 40px;
			line-height: 40px;
			font-size: 14px;
			border-radius: 5px;
			margin-right: 10px;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			&:hover {
				background: var(--afternoon);
				color: white;
			}
		}
	}

	.print-page-custom {
		width: 800px;
	}

	.print-page-container {
		display: flex;
		position: relative;
		flex-direction: column;
		width: 783.87px;
		margin-bottom: 20px;
	}
}

.print-wrapper {
	display: flex;
	position: relative;
	flex-direction: column;
	width: 100%;
	height: 1040px;
	padding: 32px;

	* {
		// background: red;
		font-family: Arial, Helvetica, sans-serif;
	}

	.header-wrapper {
		display: grid;
		grid-template-columns: 1fr auto;
		width: 100%;

		.title {
			h1 {
				line-height: 40px;
				font-size: 36px;
				text-transform: capitalize;
			}

			h2 {
				line-height: 26px;
				font-size: 20px;
				color: #434343;
				text-transform: capitalize;
			}
		}

		.company {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			text-align: right;

			.logo {
				display: flex;
				justify-content: right;
				width: 100px;
				height: 45px;
				color: var(--primary-color);
				margin-bottom: 2px;

				img {
					height: 45px;
				}
			}

			.company-contact {
				h3 {
					padding-top: 10px;
					line-height: 18px;
					font-size: 18px;
					margin-bottom: 2px;
				}

				div {
					color: #434343;

					i {
						font-size: $text-size;
						margin-right: 5px;
					}

					span {
						font-size: $text-size;
						margin-right: 10px;
					}

					span:last-child {
						margin-right: 0px;
					}
				}
			}
		}
	}

	.print-body-wrapper {
		max-height: 570px;
		overflow: hidden;

		.oneline-customer {
			display: flex;
			flex-direction: row;
			margin-bottom: 15px;
			font-size: $label-size;

			.customer-oneline-data {
				display: flex;
				flex-direction: column;
				color: #434343;
				margin-right: 15px;

				>p {
					color: black;
					margin-bottom: 10px;
				}
			}
		}

		.print-booking-wrapper {
			display: grid;
			grid-template-columns: 200px 300px 1fr;
			width: 100%;
			height: 70px;

			.print-content {
				.print-text {
					h3 {
						line-height: $label-size;
						font-size: $label-size;
						margin-bottom: 5px;
						color: #434343;
					}

					p {
						line-height: $text-size;
						font-size: $text-size;
						margin-bottom: 16px;

						&.caps {
							text-transform: capitalize;
						}
					}

					&.grids {
						display: grid;
						grid-template-columns: 1fr 1fr;
					}
				}

				.status-content {
					display: flex;
					width: 100%;
					height: 30px;
					border-radius: 5px;
					align-items: center;
					justify-content: center;
					text-transform: uppercase;
					margin-bottom: 15px;
					color: white;
				}
			}
		}

		.print-menu-wrapper {
			.list-wrapper {
				display: flex;
				flex-direction: column;
				font-size: $label-size;
				justify-content: space-between;

				.itemlist {
					display: flex;
					align-items: center;
				}

				.hrtag {
					width: 0;
					margin: 0;
					margin-bottom: 5px;
				}

				h2 {
					font-size: $label-size - 1;
					line-height: $label-size;
					padding-bottom: 2px;
					padding-right: 5px;
					margin-bottom: 10px;
					// border-bottom: 1px solid var(--primary-color);
					// width: fit-content;
					min-width: 50px;
				}

				.itemlist-container {
					display: flex;
					flex-direction: row;
					gap: 5px;
					height: fit-content;
					flex-wrap: wrap;
					padding-bottom: 10px;

					&.cols {
						width: 100%;
						flex-direction: row;
					}

					.menu-small {
						display: flex;
						width: 138px;
						min-width: 138px;
						height: 20px;
						border-radius: 5px;
						padding: 0px 5px;
						align-items: center;

						.item-icon {
							width: 15px;
							height: 15px;
							display: flex;
							justify-content: center;
							align-items: center;
							fill: #f09e9b;
							margin-right: 5px;

							.veg {
								fill: #a9ce7b;
							}
						}

						i {
							width: 15px;
							height: 15px;
							display: flex;
							justify-content: center;
							align-items: center;
							margin-right: 5px;
						}

						.menu-item {
							line-height: $text-size;
							font-size: $text-size - 2;
							color: #434343;
							overflow: hidden;
							text-transform: capitalize;
							text-overflow: ellipsis;
							white-space: nowrap;
							margin: 0px;
						}
					}
				}
			}
		}

		.print-table-grid {
			display: grid;
			grid-template-columns: 40px 1fr 150px 150px 150px;

			&.three-grid {
				grid-template-columns: 40px 1fr;
			}

			.print-table-title {
				height: 30px;
				background-color: var(--primary-color);
				color: white;
				font-size: 14px;
				padding: 5px 5px;
			}

			.print-table-booking {
				min-height: 40px;
				padding-top: 10px;

				.main-text {
					display: flex;
					gap: 15px;
					height: $label-size;
					line-height: $label-size;
					font-size: $label-size;
					margin-bottom: 10px;

					&.details {
						color: #434343;
						padding-left: 15px;
					}
				}
			}

			.amountBlock {
				display: flex;
				flex-direction: column;
				// justify-content: flex-end;
				text-align: end;
				padding-top: 10px;
				padding-right: 15px;
				line-height: $label-size;
				font-size: $label-size;

				p {
					margin-bottom: 10px;
				}

				.sub-details {
					color: #434343;
				}
			}

			.event-summary-grid {
				display: grid;
				grid-template-columns: repeat(6, 1fr);

				&>p {
					display: flex;
					gap: 15px;
					height: $label-size;
					line-height: $label-size;
					font-size: $label-size;
					margin-bottom: 10px;
					padding-top: 10px;
					justify-content: flex-end;

					&:last-child {
						position: relative;
						right: 10px;
					}
				}
			}
		}
	}

	.footer-wrapper {
		display: flex;
		flex-direction: column;
		height: 250px;
		margin-top: 10px;

		.footer-content {
			display: grid;
			width: 100%;
			grid-template-columns: 1fr 300px;

			&.summaryAgreement {
				grid-template-columns: 1fr;
			}

			// height: 100px;
			h3 {
				font-size: $label-size;
				text-decoration: underline;
			}

			// border: 1px solid orange;
			.term-condition-content {
				margin-right: 20px;
				// height: 290px;
				height: fit-content;
				overflow: hidden;
				font-size: 12px;

				h3 {
					font-size: $label-size;
					text-decoration: underline;
				}

				p {
					font-size: $text-size;
				}


				h1 {
					font-size: 1.6em;
				}

				h2 {
					font-size: 1.4em;
				}

				h3 {
					font-size: 1.2em;
				}

				h4,
				h5,
				h6 {
					font-size: 1.1em;
				}

				p {
					font-size: 1.1em;
				}
			}

			.amounts {
				display: grid;
				height: 200px;
				grid-template-columns: 100px 1fr;
				font-size: $label-size;
				line-height: $label-size;

				div {
					display: flex;
					align-items: center;
					// padding: 0px 10px;
					color: #434343;

					&.right {
						justify-content: right;
					}

					&.highlight {
						color: black;
						border-top: 1px solid rgba($color: #434343, $alpha: 0.5);
						padding-top: 2px;
					}
				}
			}

			.agreement {
				display: flex;
				align-items: end;
				justify-content: end;
				grid-column: 1 / span 2;
				width: 100%;
				height: 100px;

				p {
					width: 100%;
					border-top: 1px dotted #434343;
					font-size: $label-size;
					text-align: right;
					padding-right: 10px;
					text-transform: capitalize;
				}
			}
		}
	}
}

.pb {
	clear: both;
	page-break-before: always;
}