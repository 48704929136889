// @import "../../../assets/css/color-variable.scss";

.disableDiv {
    pointer-events: none;
    opacity: 0.4;
}

.event-detail-wrapper {
    height: 36px;
    background-color: var(--background-color);
    display: flex;
    flex-direction: row;
    cursor: pointer;

    .left-border-container {
        width: 10px;
        height: 100%;
        border-radius: 5px 0px 0px 5px;

        &.morning {
            background-color: var(--morning);
        }

        &.evening {
            background-color: var(--evening);
        }

        &.afternoon {
            background-color: var(--afternoon);
        }
    }

    .event-details-container {
        border-radius: 0px 5px 5px 0px;
        width: 120px;
        height: 100%;
        margin-left: 5px;
        display: flex;
        flex-direction: row;
        cursor: pointer;

        .venue-details-container {
            display: flex;
            flex-direction: row;
            width: 100%;
            // animation
            position: relative;
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            -webkit-transition: color 1000ms;
            transition: color 1000ms;

            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: var(--bgColor);
                transform: scaleX(0);
                -webkit-transform: scaleX(0);
                transform-origin: 0 50%;
                -webkit-transform-origin: 0 50%;
                transition: 600ms ease-out;
                -webkit-transition: 600ms ease-out;
                transition-property: transform;
                -webkit-transition-property: transform;

                border-radius: 0px 5px 5px 0px;

            }

            &:hover:before {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
                color: white;
            }

            .hall-details-container {
                width: 18px;
                height: 36px;
                border-radius: 0px 5px 5px 0px;
                opacity: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .halls-name {
                    font-size: 12px;
                    color: var(--lighter-_for-borders);
                    text-align: center;
                }
            }

            .event-details-content {
                width: 80px;
                display: flex;
                flex-direction: column;
                padding-left: 4px;
                justify-content: center;

                &:hover .event-title:nth-child(n+1) {
                    color: var(--background-color);
                }

                &:hover .event-title:nth-child(2) {

                    display: none;
                }

                &:hover .event-title:nth-child(3) {
                    display: block;
                }

                .event-title {
                    // height: 50%;
                    width: 100%;
                    color: #474747;
                    font-size: 10px;
                    text-align: left;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 0 !important;
                    cursor: pointer;


                    &:nth-child(n+2) {
                        color: #9d9d9d;
                        font-size: 10px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    &.status {
                        display: none;
                    }
                }
            }
        }

        .event-status-container {
            height: 100%;
            width: 14px;
            margin: 0px 3px;
            display: flex;
            // justify-content: center;
            align-items: center;

            // .tooltip-status {
            //     visibility: hidden;
            //     width: 60px;
            //     background-color: rgba(0, 0, 0, 0.2);
            //     color: #fff;
            //     text-align: center;
            //     border-radius: 6px;
            //     // padding: 5px 0;
            //     font-size: 12px;
            //     margin-left: 15px;
            //     height: 20px;


            //     /* Position the tooltip */
            //     position: absolute;
            //     z-index: 1;
            // }

            // &:hover {
            //     .tooltip-status {
            //         visibility: visible;
            //     }
            // }

            .status-content {
                width: 14px;
                height: 14px;
                border-radius: 1px;

                // @extend .bg-inset;
                .event-status {
                    width: 8px;
                    height: 8px;
                    margin: 3px;
                    background-color: var(--bg);
                    border-radius: 1px;
                }
            }
        }

    }

    .selected-booking{
        height: 100%;
        width: 20px;
        display: flex;
        border-radius: 0px 5px 5px 0px;
        background: var(--confirmed);
        color: white;
        align-items: center;
        justify-content: center;
        font-size: 12px;
    }
}