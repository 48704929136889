@import '../../assets/css/color-variable.scss';

.table-wrapper {
	width: 100%;
	min-height: 100px;
	// max-height: 600px;
	display: flex;
	flex-direction: column;
	align-items: center;

	&.menu-service {
		min-height: 500px;
	}
}

th,
td {
	text-align: left;
	padding-left: 8px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 13px;
}

.table-container {
	width: 100%;
	border-spacing: 0px 10px;
	border-collapse: separate;
	table-layout: fixed;
}

.table-header-container {
	border-bottom: 2px solid var(--secondary-color);
	// margin-right: 30px;
	padding: 0 5px;
	color: var(--secondary-color);
	font-size: 14px;
}

.table-header {
	color: #474747;
	font-size: 14px;
}

.data-table-row {

	border-radius: 10px;
	border: none;
	outline: none;
	margin-top: 20px;

	&.editable {
		height: 50px;
	}

	&.normal {
		height: 30px;
	}

	&.inventory {
		height: 32px;
	}
}

.icon-cell {

	height: 30px;
	width: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px 0px 0px 10px;

	i {
		color: var(--menu-icon-green);
	}

}

.body-table-cell {

	&.vat_amount,
	&.e_rate,
	&.paid_amount {
		// color: red;
		text-overflow: unset;
		text-align: end;
		padding-right: 30px;
	}

	&.pending {
		color: var(--pending);
		font-weight: 600;
	}

	&.excess {
		color: var(--payment);
		font-weight: 600;
	}
}