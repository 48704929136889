.datablock-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 285px;
    height: 119px;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
    // box-sizing: border-box;

    .label {
        display: flex;
        line-height: 16px;
        color: var(--text-dark-grey);
        width: 100%;
        height: 16px;
    }

    .icon-label-wrapper {
        display: flex;
        height: 30px;

        .icon-label {
            @extend .label;
            line-height: 30px;
        }

       
    }

    .data-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        bottom: 15px;
        right: 15px;
        i {
            text-align: right;
            font-size: 20px;
            line-height: 20px;
            // background-color: red;
            margin-bottom: 10px;
        }

        & p {
            text-align: right;
            margin: 0px 0px;
        }

        .sub {
            font-size: 12px;
            color: var(--highlight-grey);
            line-height: 12px;
            padding-bottom: 10px;
        }

        .data-content {
            font-size: 28px;
            line-height: 28px;

        }
        .drop-size{
            // color: green;
            font-size: 20px;
            line-height: 20px;
        }
    }

    &.sm {
        width: 135px;
    }

    &.sm-l {
        width: 135px;
        height: 253px;
    }
}

.random{
    width: 135px;
    height: 119px;
    margin-bottom: 15px;
    background-color: red;
    border: 1px solid green;
}