.payment-detail-dropdown-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-left: 20px;
	padding: 5px 20px;
	font-size: 13px;

	&.payment-notes {
		.payment-note-block {
			padding: 4px 8px;
			border-radius: 4px;
			border: 1px solid var(--highlight-grey);
			color: var(--text-dark-grey);
		}
	}

	.payment-history-dropdown-title {
		font-weight: 600;
	}
	.payment-history-dropdown-value {
		width: 100%;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
		line-clamp: 1;
		-webkit-line-clamp: 1;
	}
}

.payment-history-button-wrapper {
	display: flex;
	justify-content: flex-end;
	align-items: center;

	> button {
		margin-right: 10px;
	}

	.payment-history-edit-button {
		width: 30px;
		height: 30px;
		border-radius: 5px;
	}
}

.payment-detail-dropdown-wrapper {
	display: flex;
}
