@import "../../../assets/css/color-variable.scss";

.venue-name-wrapper {
	width: 285px;
	height: 52px;
	border-radius: 5px;
	padding: 10px;
	display: flex;

	.venue-color-container {
		height: 30px;
		width: 30px;
		border-radius: 5px;
	}

	.venue-name-label {
		margin-left: 15px;
		height: 30px;
		width: 220px;
		line-height: 30px;
		color: var(--text-midgray);
		text-transform: capitalize;

	}
}

.booking-status-container {
	width: 285px;
	height: 52px;
	text-align: center;
	border-radius: 5px;
	font-size: var(--h3);
	line-height: 52px;
	text-transform: uppercase;
	background-color: var(--bg);
	color: white;

}

.booking-id-wrapper {
	display: flex;
	height: 52px;
	padding: 5px 10px;
	// width: 135px;
	flex: 1;
	border-radius: 5px;


	.id-icon-container {
		height: 42px;
		width: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: var(--text-midgray);
	}

	.id-container {
		display: flex;
		flex-direction: column;
		// width: 82px;
		flex: 1;

		.id-title-label {
			font-size: var(--h6);
			width: 100%;
			text-align: right;
			color: var(--text-midgray);
		}

		.id-label {
			font-size: var(--h5);
			width: 100%;
			text-align: right;
			color: var(--text-dark-grey);
			height: 19px;
			margin-top: 3px;

		}
	}
}

.booking-halls-wrapper {
	height: 52px;
	width: 285px;
}

.booking-day-wrapper {
	height: 120px;
	width: 285px;
	// background-color: salmon;

	.booking-day-container {
		height: 120px;
		width: 135px;
		background-color: var(--bg);
		position: relative;
		padding: 10px;
		border-radius: 5px;
		color: white;

		.booking-day-icon {
			position: absolute;
			margin: auto;
			height: 30px;
			width: 30px;

			.fa-sun {

				height: 30px;
				width: 30px;
			}
		}

		.booking-day-label {
			position: absolute;
			margin: auto;
			right: 17px;
			bottom: 44px;
			font-size: var(--h6);
		}

		.booking-time-label {
			position: absolute;
			margin: auto;
			right: 17px;
			bottom: 20px;
			font-size: var(--h3);
			text-transform: uppercase;
		}
	}

	.booking-date-container {
		height: 120px;
		width: 135px;

		.booking-date-content {
			display: grid;
			grid-template-areas:
				'day year year'
				'day month month';
			padding: 10px;
			width: 135px;
			height: 52px;
			border-radius: 5px;

			.booking-day {
				grid-area: day;
				width: 40px;
				height: 32px;
				font-size: var(--h2);
				line-height: 30px;
				border-right: 2px var(--text-midgray) solid;
				color: var(--text-dark-grey);
				padding-left: 2px;

			}

			.booking-month {
				grid-area: month;
				height: 18px;
				font-size: var(--h5);
				text-align: end;
				color: var(--text-dark-grey);
			}

			.booking-year {
				grid-area: year;
				height: 14px;
				font-size: var(--h6);
				text-align: end;
				color: var(--text-midgray);

			}
		}
	}
}

.persistent-button-wrapper {
	display: flex;
	flex: 1;
	height: 40px;
	border-radius: 10px;
	justify-content: center;
	align-items: center;
	background-image: conic-gradient(from var(--border-angle), transparent 20%, #1c1222, #523560);
	animation: bg-spin 3s linear infinite;

	.persistent-button {
		display: flex;
		width: 275px;
		height: 35px;
		border-radius: 10px;
		justify-content: center;
		align-items: center;
		background-color: var(--background-color);

		@keyframes bg-spin {
			to {
				--border-angle: 1turn;
			}
		}

	}

	&:hover {
		animation-play-state: paused;
		cursor: pointer;
	}
}

@property --border-angle {
	syntax: "<angle>";
	inherits: true;
	initial-value: 0turn;
}