.search-result-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content;
    // border: 1px solid red;

    .customer-list-sm {
        display: flex;
        flex-direction: column;
        width: 285px;
        height: 60px;
        margin-bottom: 15px;
        padding: 5px;
        border-radius: 5px;
        // border: 1px solid green;
        text-transform: capitalize;

        &:hover {
            background: var(--afternoon);
            color: white;
            cursor: pointer;
        }

        &.custom-selection{
            background: var(--confirmed);
            color: white;
        }
    }
    .customer-list-item-name{
        margin: 0px;
    }
    .customer-list-item-num{
        color: grey;
        font-size: 14px;
        margin: 0px;
    }
}

.clickable-message{
    display: flex;
    height: fit-content;
    // border: 1px solid red;
    border-radius: 5px;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    font-size: 14px;

    &.selected{
        background: var(--highlight-green);
        color: white;
    }
    &:hover {
        background: var(--afternoon);
        color: white;
        cursor: pointer;
    }
}