.service-item-wrapper {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	width: 285px;
	min-height: 50px;
	padding: 10px;
	border-radius: 5px;

	&.saved {
		pointer-events: none;
	}

	.service-info-container {
		display: grid;
		height: 45px;
		grid-template-columns: 30px 1fr;
		margin-bottom: 5px;

		.service-icon {
			width: 30px;
			height: 30px;
			border-radius: 10px;
			display: flex;
			justify-content: center;
			align-items: center;

			&.selected,
			&.saved {
				background: #f09e9b 0% 0% no-repeat padding-box;
				color: var(--background-color);
			}
		}

		.service-info {
			display: flex;
			flex-direction: column;

			& > div {
				display: flex;
				justify-content: right;
			}

			.service-b-price {
				color: var(--text-midgray);
				padding-right: 2px;
			}
		}
	}

	// .service-details-container {
	.service-action-wrapper {
		display: grid;
		column-gap: 5px;
		grid-template-columns: 1fr 1fr;

		& > div {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 5px;
			cursor: pointer;
		}

		.save-service {
			background: var(--highlight-green);
			color: white;
		}

		.edit-service {
			&:hover {
				background: var(--highlight-green);
				color: white;
			}
		}

		.delete-service {
			&:hover {
				background: var(--highlight-red);
				color: white;
			}
		}
	}
	// }

	.save-button {
		position: absolute;
		top: 30px;
		right: 10px;
		margin: auto;
		font-size: 12px;
		width: 17px;
		color: var(--text-midgray);
		// background-color: saddlebrown;
	}

	// .item-icon {
	// 	width: 30px;
	// 	height: 30px;
	// 	border-radius: 10px;
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// 	// position: absolute;
	// 	// width: 30px;
	// 	// height: 30px;
	// 	// border-radius: 10px;
	// 	// display: flex;
	// 	// justify-content: center;
	// 	// align-items: center;
	// 	// top: 10px;
	// 	// left: 10px;

	// 	&.selected,
	// 	&.saved {
	// 		background: #f09e9b 0% 0% no-repeat padding-box;
	// 		color: var(--background-color);
	// 	}
	// }

	// .item-name {
	// 	position: absolute;
	// 	height: 40px;
	// 	bottom: 10px;
	// 	left: 10px;
	// 	right: 10px;
	// 	color: #474747;
	// 	font-size: 14px;
	// 	text-align: end;
	// 	white-space: nowrap;
	// 	overflow: hidden;
	// 	text-overflow: ellipsis;
	// }

	// .item-price {
	// 	position: absolute;
	// 	top: 10px;
	// 	right: 10px;
	// 	margin: auto;
	// 	font-size: 12px;
	// 	width: 60px;
	// 	text-align: right;
	// 	color: var(--text-midgray);
	// }

	// .item-type {
	// 	position: absolute;
	// 	bottom: 55px;
	// 	right: 10px;
	// 	margin: auto;
	// 	font-size: 12px;
	// 	width: 60px;
	// 	color: var(--text-midgray);
	// 	text-align: end;
	// }
}
