@import '../../assets/css/color-variable.scss';

.add-venue-permission-view-btn {
    height: 30px;
    width: 30px;
    border-radius: 5px;
}

.venue-role-content {
    display: flex;
    flex-direction: row;
    gap: 15px;
    height: 30px;
    margin-top: 10px;

}

.venue-role-container {
    display: flex;
    flex-direction: column;
    width: 285px;
    height: 50px;
}

.dropdown-title-container {
    // background-color: red;
    display: flex;
    font-size: var(--h6);
    color: var(--highlight-grey);
}