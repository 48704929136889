@import "../../assets/css/color-variable.scss";

.data-table-row {
	cursor: pointer;
}

.booking-time-cell {
	height: 30px;
	width: 30px;
	border-radius: 10px 0px 0px 10px;
	display: flex;
	box-shadow: -2px -2px 4px #fff,
		2px 2px 4px rgba($color: #1c1222, $alpha: 0.2);
	justify-content: center;
	align-items: center;
	color: #f8f8fc;

	&.morning {
		background-color: var(--morning);
	}

	&.evening {
		background-color: var(--evening);
	}

	&.afternoon {
		background-color: var(--afternoon);
	}

	&.payment {
		background-color: var(--payment);
	}

	&.discount {
		background-color: var(--discount);
	}

	&.void {
		background-color: var(--void);
	}

	&.refund {
		background-color: var(--refund);
	}
}

.booking-status-cell {
	width: 75px;
}

.booking-status-wrapper {
	height: 24px;
	width: 54px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
}

.booking-status-content {
	width: 55px;
	height: 20px;
	font-size: 11px;
	color: #f8f8fc;
	// background-color: goldenrod;
	text-align: center;
	line-height: 20px;
	border-radius: 5px;
	box-shadow: -2px -2px 4px #fff,
		2px 2px 4px rgba($color: #1c1222, $alpha: 0.2);

	&.hold {
		background-color: var(--hold);
	}

	&.inquiry {
		background-color: var(--inquiry);
	}

	&.booked {
		background-color: var(--booking);
	}

	&.advance {
		background-color: var(--advance);
	}

	&.paid {
		background-color: var(--paid);
	}

	&.pending {
		background-color: var(--pending);
	}

	&.complete {
		background-color: var(--complete);
	}

	&.canceled {
		background-color: var(--canceled);
	}

	&.process {
		background-color: var(--process);
	}

	&.confirmed {
		background-color: var(--confirmed);
	}
}

.booking-time {
	width: 30px;
}

.booking-id {
	width: 50px;
}

// .booking-date {
//     width: 15%;
// }

// .booking-venue-name {
//     width: 32%;
// }

// .booking-customer-name {
//     width: 32%;
// }

// .booking-event {
//     width: 20%;
// }

// .booking-catering {
//     width: 20%;
// }

// .booking-pax {
//     width: 15%;
// }

// .booking-rate {
//     width: 15%;
// }

.booking-status {
	width: 70px;
}

.table-controller-container {
	position: relative;
	width: 100%;
	height: 30px;
}

.booking-status-view {
	&.hold {
		background-color: var(--hold);
	}

	&.inquiry {
		background-color: var(--inquiry);
	}

	&.booked {
		background-color: var(--booking);
	}

	&.advance {
		background-color: var(--advance);
	}

	&.paid {
		background-color: var(--paid);
	}

	&.pending {
		background-color: var(--pending);
	}

	&.complete {
		background-color: var(--complete);
	}

	&.canceled {
		background-color: var(--canceled);
	}

	&.process {
		background-color: var(--process);
	}

	&.confirmed {
		background-color: var(--confirmed);
	}
}
