.bookings-halls-wrapper {
    height: 45px;
    width: 285px;
    // background-color: aqua;

    .booking-halls-content {
        // background-color: skyblue;

        &:nth-child(n+2) {
            margin-left: 10px;
        }
    }

    .bookings-halls-container {
        height: 30px;
        width: 285px;
        // background-color: violet;
        display: flex;
        // gap: 12px;

        .booking-halls-label {
            width: 88px;
            height: 30px;
            // background-color: saddlebrown;
            border-radius: 5px;
            text-align: center;
            line-height: 30px;
            cursor: pointer;
        }

        .booking-halls-checkbox {

            display: none;
        }

        .booking-halls-checkbox:checked+.booking-halls-label {
            background-color: var(--confirmed);
            color: white;

        }
    }

    .bookings-halls-error {
        height: 15px;
        width: 100%;
        color: var(--highlight-red);
        font-size: var(--h7);
        text-align: end;
        margin-top: 5px;
    }
}