// .notification {
//   width: 260px;
//   background: transparent linear-gradient(105deg, #f7f7fa 0%, #f4f4f8 100%) 0%
//     0% no-repeat padding-box;
//   padding: 13px 50px;
// }

// .notification label {
//   padding: 2.5px 10px;
//   font-size: 10px;
//   display: block;
// }

// .notification-label {
//   border-radius: 5px 5px 0px 0px;
// }

// .notification-message {
//   border-radius: 0px 0px 5px 5px;
//   background: transparent linear-gradient(105deg, #f7f7fa 0%, #f4f4f8 100%) 0%
//     0% no-repeat padding-box;
//   box-shadow: 2px 2px 4px #1c122233;
// }

// .notification-label {
//   background-color: #87ccd5;
//   color: #f8f8ff;
// }

// .notification-message {
//   color: #474747;
//   background-color: white;
//   font-size: 11px;
// }

.notification-wrapper {
	width: 300px;
	margin-bottom: 10px;
	height: 70px;
	padding: 10px;
	border-radius: 2px;

	&.unseen {
		background-color: var(--update-highlight);
	}

	&:hover {
		// cursor: pointer;
		background-color: var(--hover-highlight);
	}
}

.notification-header-container {
	// background-color: aqua;
	display: flex;
	justify-content: space-between;
}

.notification-header-label {
	font-size: var(--h6);
	color: var(--text-dark-grey);

	&.date {
		color: var(--text-midgray);
	}
}

.notification-message {
	text-align: left;
	margin-top: 5px;
	font-size: var(--h6);
	color: var(--text-midgray);
}
