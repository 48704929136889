.monthly-calendar-wrapper {
	flex: 1;
	padding: 0 16px 16px 16px;
	font-size: 12px;
	display: flex;

	.calendar-container {
		flex: 1;
		display: flex;
		flex-direction: column;

		.week-container {
			display: flex;
			border: 1px solid #D8D8D866;
			border-top: 0;

			.week-item-container {
				flex: 1;
				padding: 8px 0;
				text-align: center;
				font-size: 1.2em;
				font-weight: 800;
				color: var(--text-dark-grey);
			}
		}

		.dates-container {
			flex: 1;
			display: flex;
			flex-wrap: wrap;

			.day-container {
				min-width: calc(100% / 7);
				flex: 1;
				padding: 8px 4px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				border: 1px solid #D8D8D866;
				background-color: var(--background-color);
				position: relative;
				height: auto;
				min-height: 120px;
				cursor: pointer;

				&:hover {
					border-color: var(--afternoon);
				}

				.day-mainText {
					position: absolute;
					left: 4px;
					top: 4px;
					font-size: 1.72em;
					font-weight: 600;
					color: var(--primary-color);
				}

				.day-subText {
					position: absolute;
					bottom: 4px;
					right: 4px;
					font-size: 1.2em;
					font-weight: normal;
					color: var(--text-midgray);
				}

				&.inactive {
					opacity: 0.4;
					pointer-events: none;
				}

				&.today {
					border-left: 4px solid var(--afternoon);

					.day-mainText,
					.day-subText {
						color: var(--afternoon);
					}
				}

				&.booked {
					background-color: #f1f1fc;
				}

				&.selected {
					background-color: var(--hover-highlight);

					.day-mainText,
					.day-subText {
						color: var(--highlight-green);
					}
				}

				.booked-marker-container {
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-start;
					align-items: center;

					.booked-marker {
						width: 24px;
						height: 20px;
						border-radius: 4px;
						margin: 2px;
						display: flex;
						align-items: center;
						justify-content: center;
						color: var(--background-color);
						font-size: 1em;

						&.morning {
							background-color: var(--morning);
						}

						&.afternoon {
							background-color: var(--afternoon);
						}

						&.evening {
							background-color: var(--evening);
						}
					}
				}

			}
		}
	}
}