@import '../../assets/css/color-variable.scss';

.venue-permission-row {
    min-height: 50px;
    justify-content: center;
    align-items: center;
}

.venue-permission-wrapper {
    width: 940px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 5px;
}


.venue-cell {
    border-right: 10px solid var(--highlight-green);
    border-radius: 0px 10px 10px 0;
    text-align: center;
    cursor: pointer;
    border-left: 2px solid var(--highlight-green);

    &.inactive {
        border-right: 10px solid var(--highlight-red);
        border-left: 2px solid var(--highlight-red);
    }
}

.employee-id {
    width: 75px;
}

.employee-name {
    width: 22%;
}

.employee-phone {
    width: 15%;
}

.employee-email {
    width: 28%;
}

.employee-role {
    width: 12%;
}

.employee-action {
    width: 100px;
}

.image-cell {
    height: 50px;
    width: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.employee-image {
    height: 50px;
    width: 50px !important;
}

.fa-user-alt {
    color: var(--text-light-grey);
}

.id-cell {
    text-align: center;
}




@media only screen and (max-width: 1280px) {

    .employee-action {
        width: 7%;
    }

    .venue-permission-wrapper {
        width: 870px;
    }

}