.page-wrapper {
    width: 100%;
    height: 800px;
    position: relative;


    .extra-page-container {
        width: 650px;
        height: 438px;
        border-radius: 20px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        right: 0;
        text-align: center;

        .extra-page-title {
            top: 20px;
            left: 0;
            right: 0;
            position: absolute;
        }

        .extra-page-sub-title {
            top: 75px;
            left: 0;
            right: 0;
            position: absolute;
            font-size: 20px;
            color: var(--text-light-grey);
        }

        .extra-page-message {
            height: 40px;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            margin: auto;
            position: absolute;
        }

        .extra-page-button-container {
            height: 40px;
            left: 0;
            right: 0;
            bottom: 0;
            top: 390px;
            position: absolute;

            .page-notfound-back-button {
                width: 100px;
                height: 30px;
                border-radius: 40px;
                float: right;
                margin-right: 20px;
            }
        }
    }
}