@import "./color-variable.scss";
@import "./size-variable.scss";

$color-1: var(--shadow-color-dark-rgba);

.form-label {
	font-size: var(--h6);
	color: var(--text-midgray);
	// margin-bottom: 5px;
	height: 12px;
}


//main wrapper used in all pages
.main-wrapper {
	background-color: var(--background-color);
	height: 100vh;
	overflow: hidden;

	//top navigation wrapper
	.nav-wrapper {
		z-index: 999;
		width: 100%;
		height: 40px;
		position: fixed;
		vertical-align: top;
		top: 0;
		left: 0;
	}

	//content wrapper
	.content-wrapper {
		width: 100%;
		display: flex;
		flex-direction: row;
		position: relative;
		// padding-top: 40px;

		//side nav wrapper
		.side-nav-wrapper {
			z-index: 99;
			min-height: 200px;
			position: fixed;
		}

		//main content wrapper
		.main-container {
			width: 100%;
			height: 100vh;
			display: flex;
			align-items: center;
			flex-direction: column;
			padding-top: 54px;
			padding-bottom: 40px;
			overflow: scroll;
		}

		.main-container::-webkit-scrollbar {
			display: none;
		}
	}
}

.bodered {
	box-shadow: -2px -2px 4px var(--shadow-color-light), 2px 2px 4px $color-1;
}

.bg-default {
	background-image: linear-gradient(120deg,
			var(--gradient-background-one),
			var(--gradient-background-two));

	box-shadow: -2px -2px 4px var(--shadow-color-light), 2px 2px 4px $color-1;
}

.bg-inset {
	background-image: linear-gradient(107deg,
			var(--gradient-background-one),
			var(--gradient-background-two));
	box-shadow: inset 2px 2px 4px $color-1,
		inset -2px -2px 4px var(--shadow-color-light);
}

.button-wrapper {
	cursor: pointer;
	border: 0;
	outline: 0;
	@extend .bg-default;
	color: #474747;
	// padding: 0 !important;
	font-size: var(--h5);

	&:hover,
	&:active {
		border: 0;
		outline: 0;
		background: var(--afternoon);
		color: white;
		// color: var(--primary-color);
		// background: linear-gradient(128deg, var(--backgroung-color), var(--backgroung-color));
	}

	&:disabled {
		pointer-events: none;
		opacity: 0.5;
		cursor: default;
	}


}

.colored-button-wrapper {
	@extend .bodered;
	width: 100px;
	height: 30px;
	border-radius: 5px;
	cursor: pointer;
	border: 0;
	outline: 0;
	padding: 0 !important;



	&:hover,
	&:active {
		border: 0;
		outline: 0;
		opacity: 0.9;
	}


}

.default-hr {
	width: 100%;
	height: 2px;
	margin: 20px 5px !important;
	background-color: var(--text-dark-grey)
}

.button-primary {
	@extend .button-wrapper;
	width: 100px;
	height: 30px;
	border-radius: 5px;

	&:disabled {
		cursor: default;
		opacity: 0.5;
	}

	&.button-red {
		background: var(--highlight-red);
		color: white;
	}

	&.button-green {
		background: var(--highlight-green);
		color: white;
	}

}

.button-primary-icon {
	@extend .button-wrapper;
	width: 30px;
	height: 30px;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;

	&:disabled {
		cursor: default;
		opacity: 0.5;
	}
}

.button-lg {
	@extend .button-wrapper;
	width: 150px;
	height: 30px;
	border-radius: 5px;

	&:disabled {
		cursor: default;
		opacity: 0.5;
	}
}

.button-lg-colored {
	@extend .bodered;
	width: 150px;
	height: 30px;
	border-radius: 5px;

	&:disabled {
		cursor: default;
		opacity: 0.5;
	}
}

/* css to manage overflow text (...) */
.overflow {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

// //css used for login, signup and complete registration component
.signin-page-wrapper {
	background-color: var(--background-color);
	// background-color: red;
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.signup-main-container {
	width: 650px;
	display: flex;
	flex-direction: column;
}

.company-title {
	height: 30px;
	text-align: center;
}

.form-sub-title {
	text-align: center;
	width: 100%;
}


.form-main-content {
	height: 438px;
	width: 650px;
	border-radius: 10px;

	.form-header {
		height: 60px;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}

	.form-body {
		height: 318px;
		overflow: auto;

		.form-body-container {
			width: 100%;
			justify-content: center;
			display: flex;
			flex-direction: column;
		}

		&.duplication {
			justify-content: center;
			display: flex;
			flex-direction: row;
		}

		&.hide-scroll {
			&::-webkit-scrollbar {
				display: none;
			}
		}

		.form-panel-left {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;

			.image-container {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 250px;
				width: 250px;
			}

		}

		.form-panel-right {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}

	.form-footer {
		height: 60px;
		padding: 10px var(--form-padding);
		width: 100%;

		&.one {
			display: flex;
			justify-content: flex-end;
		}

		&.two {
			display: flex;
			justify-content: space-between;
		}

		.three-button {
			gap: 20px;
			display: flex;
		}
	}
}



.form-navigator-wrapper {
	width: 100%;
	display: flex;
	padding-left: var(--form-padding);
	font-size: var(--h5);
	height: 25px;

	&.venue {
		float: right;
		margin-bottom: 10px;
		justify-content: flex-end;
		padding-top: 0px;
	}

	.form-navigator-title {
		color: var(--highlight-grey_-e74c3c);
		margin-right: 25px;


		&.selected {
			color: var(--primary-color);
			border-bottom: 2px solid var(--primary-color);
			border-top: none;
			border-left: none;
			border-right: none;
		}
	}
}

.form-description-title {
	font-size: var(--h5);
	color: var(--text-dark-grey);
	padding-left: var(--form-padding);
	padding-bottom: 15px;

	&:nth-child(n+2) {
		padding-top: 10px;
	}
}

.form-input-container {
	padding: 0px var(--form-padding);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	min-height: 70px;

	&.inner {
		flex-direction: row;
		justify-content: space-between;
		padding: 0px;
		min-width: 285px;
	}

	.vat-rec-button {
		width: 135px;
		height: 62px;
		margin-bottom: 5px;

	}

	.nep-button-tigger {
		@extend .vat-rec-button;
		display: flex;
		padding: 5px;
		flex-direction: column;

		>p {
			font-size: 12px;
			margin-bottom: 0px;
			// border: 1px solid red;

			&:last-child {
				padding-top: 4px;
				font-size: 14px;
				border-bottom: 1px solid lightgray;
			}
		}


	}
}


.form-notice-title {
	font-size: var(--h2);
	color: var(--text-dark-grey);
	padding-top: 30px;
	padding-bottom: 2px;
	text-align: center;

}

.form-notice-sub-title {
	font-size: var(--h3);
	color: var(--text-midgray);
	padding-top: 10px;
	padding-bottom: 2px;
	text-align: center;

}

.form-notice-message {
	font-size: var(--h4);
	color: var(--text-dark-grey);
	margin-top: 100px;
	padding-bottom: 2px;
	text-align: center;
}

.form-notice-controller {
	margin-top: 50px;
	padding-bottom: 2px;
	display: flex;
	justify-content: space-around;
}

.no-data-display {
	display: flex;
	justify-content: center;
	line-height: 18px;
	gap: 10px;
	margin: 15px 0px;
	border: 2px dotted lightgrey;
	padding: 10px;
	color: lightgray;
}

@media only screen and (max-width: 540px) {

	.signup-main-container {
		width: 325px;
		min-height: 628px;
	}

	.form-main-content {
		position: relative;
		height: 450px;
		width: 325px;

		.form-body {
			position: relative;
			height: 330px;
			overflow-y: scroll;

			&.duplication {
				flex-direction: column;
				overflow: auto;
			}

			.form-panel-left {
				margin-top: 100px;
				margin-bottom: 20px !important;
			}

			&::-webkit-scrollbar {
				width: 2px;
			}

			/* Track */
			&::-webkit-scrollbar-track {
				background: #f1f1f1;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: #523563;
			}
		}

		.form-footer {
			.three-button {
				width: 60%;
				justify-content: space-between;
				gap: 0px;
				display: flex;
				margin-left: 5px;
			}
		}
	}


	.form-navigator-wrapper {
		padding-left: var(--form-padding-540);
	}

	.form-description-title {
		padding-left: var(--form-padding-540);
	}

	.form-input-container {
		flex-direction: column;
		min-height: 100px;

		&.inner {
			flex-direction: row;
			min-height: 70px;
		}
	}

	.button-primary {
		width: fit-content;
		min-width: 60px;
		height: 30px;
		border-radius: 5px;
		padding-left: 5px;
		padding-right: 5px;
	}

}


/* new main innner contaner css for new design starting from company settings */
.profile-navigation-container {
	width: 100%;
	padding: 15px 70px;
	// margin-top: 1%;

	.profile-path {
		font-size: 14px;

		.current-page-path {
			padding-bottom: 2px;
			border-bottom: 2px #523563 solid;
		}

		.previous-page-path {
			cursor: pointer;
		}
	}

	.navigator-content {
		padding: 5px 0px;
		font-size: 14px;
		color: var(--text-midgray);
		cursor: pointer;
		display: flex;
		flex-wrap: wrap;
		row-gap: 15px;

		.settings-view-title {

			&:nth-child(n + 2) {
				margin-left: 60px;
			}

			&.selected {
				color: var(--primary-color);
				border-bottom: 2px solid var(--primary-color);
				border-top: none;
				border-left: none;
				border-right: none;

			}
		}


	}

}

.inner-container {
	width: 1260px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	display: grid;
	grid-template-columns: repeat(4, 0fr);
	column-gap: 40px;
	row-gap: 10px;
	padding-bottom: 25px;
	// border: 1px solid red;
	// background-color: red;


	&.full {
		display: flex;

		.calendar-content {
			display: grid;
			grid-template-columns: auto auto auto auto auto auto auto;
			margin-top: 25px;
			overflow-x: scroll;
			gap: 15px;
			padding-bottom: 10px;

			&::-webkit-scrollbar {
				display: none;
			}
		}
	}

	&.button {
		display: flex;
		justify-content: flex-end;
		flex-direction: row;

	}

	.main-inner-container {
		width: 285px;
		min-height: 285px;
		position: relative;
		// background-color: red;
		// border: 1px solid green;

		&.add-booking {
			min-height: 465px;
		}

		&.company {
			min-height: 350px;
		}

		&.inventory {
			min-height: 120px;
		}


		.inner-content {
			display: flex;

			&.rows {
				// background-color: #3498DB;
				justify-content: space-between;
				flex-direction: row;
			}

			&.cols {
				// background-color: #18ec70;
				flex-direction: column;
				justify-content: space-between;
			}

			&.button {
				// background-color: #abd6bd;
				flex-direction: row;
				align-items: flex-end;
				justify-content: flex-end;
				gap: 10px;
				margin: auto;
				bottom: 15px;
				position: absolute;
				width: 285px;
			}
		}
	}

}

@media only screen and (max-width: 1260px) {


	/* new main innner contaner css for new design starting from company settings */
	.inner-container {
		max-width: 945px;
		grid-template-columns: repeat(3, 0fr);

		&.full {
			.calendar-content {
				&::-webkit-scrollbar {
					display: block;
					height: 6px;
				}
			}
		}

	}
}

@media only screen and (max-width: 945px) {

	/* new main innner contaner css for new design starting from company settings */
	.inner-container {
		max-width: 630px;
		grid-template-columns: repeat(2, 0fr);

	}
}


@media only screen and (max-width: 630px) {

	/* new main innner contaner css for new design starting from company settings */
	.inner-container {
		max-width: 315px;
		grid-template-columns: repeat(1, 0fr);
		row-gap: 20px;
		column-gap: 0px;
		justify-content: center;

		.main-inner-container {
			&:nth-child(n+4) {
				min-height: 150px;
			}

			.inner-content {
				&.button {
					position: static;
					margin-top: 15px;
				}
			}
		}
	}

}

@media only screen and (max-width: 860px) {



	.profile-navigation-container {

		.navigator-content {
			row-gap: 15px;
			column-gap: 40px;

			.settings-view-title {
				&:nth-child(n + 2) {
					margin-left: 0px;
				}
			}
		}
	}
}



/* customer and user profile setup */
.imagefixing {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.profile-img-contanier {
	width: 285px;
	height: 253px;
}


//Tooltip Working
.tooltip-parent {
	position: relative;

	&:hover .tooltip {
		display: flex;
	}
}

.tooltip {
	display: none;
	opacity: 0;
	position: absolute;
	top: -24px;
	left: 0px;
	width: fit-content;
	white-space: nowrap;
	justify-content: center;
	align-items: center;
	height: 20px;
	font-size: 12px;
	line-height: 12px;
	background-color: var(--shadow-color-dark);
	padding: 0px 4px;
	border-radius: 5px;
	animation: show 0.5s forwards;
	animation-delay: 1s;

	&:before {
		content: "";
		position: absolute;
		top: 100%;
		left: 4px;
		width: 0px;
		border-top: 5px solid var(--shadow-color-dark);
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
	}

	@keyframes show {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

}