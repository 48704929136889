@import "../../../assets/css/color-variable.scss";
@import "../../../assets/css/main.scss";

.upload-btn-wrapper {
    @extend .button-wrapper;
    position: relative;
    overflow: hidden;
    display: block;
    border: none;
    border-radius: 40px;
}

.upload-btn {
    @extend .bodered;
    border: none;
    border-radius: 5px;
    position: absolute;
    width: 30px;
    color: #FFFF;
    right: 5px;
    top: 5px;
    background-color: var(--highlight-green);
}

input[type="file"] {
    font-size: 13px;
    width: 220px;
    cursor: pointer;
}

@media only screen and (max-width:945px) {
    .upload-btn-wrapper {
        display: none;

    }
}