.pr-wrapper {
	--img-width: 80px;
	width: 842px;
	height: 360px;
	// width: 842px;
	// height: 1190px;
	// height: 1185px;
	padding: 24px;
	padding-bottom: 16px;
	background-color: white;
	display: grid;
	// grid-template-rows: 100px 135px 60px;
	gap: 15px;
	overflow: hidden;
	position: relative;
	// box-sizing: border-box;
	border-bottom: 1px solid grey;

	.pr-header {
		display: flex;
		flex-direction: column;
		position: relative;

		.pr-caterme {
			display: flex;
			height: 100px;
			width: 100px;
			right: 15px;
			top: 0;
			position: absolute;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border-radius: 10px;
			background-color: white;
			border: 4px solid #523563;

			.pr-cater-me-logo {
				margin-left: 8px;
				width: 61px;
				height: 49px;
			}
		}

		.pr-title-wrap {
			display: flex;
			flex: 1;
			padding-left: 15px;
			background-color: #523563;
			// justify-content: end;
			align-items: end;
			margin-bottom: 10px;

			.pr-title {
				display: flex;
				color: white;
				font-size: 20px;

			}

		}

		.pr-message {
			padding-left: 15px;
		}

	}

	.pr-container {
		display: flex;
		padding: 0px 15px;
		flex: 1;
		gap: 8px;

		.pr-sec {
			display: flex;
			flex: 1;
			gap: 10px;
			flex-wrap: wrap;

			&.pr-right {
				display: flex;
				flex: 1;
				max-width: 160px;
				justify-content: end;
				align-items: end;
			}

			.pr-data-block {
				flex: 1;
				min-width: 140px;

				.pr-data-label {
					color: #9d9d9d;

					&.pr-r {
						text-align: right;
					}
				}

				.pr-data {
					font-size: 24px;
					text-align: right;
				}
			}

			.pr-verified-block {
				>i {
					margin-right: 10px;
				}
			}

			.pr-paydate {
				color: #9d9d9d;
				text-align: right;
			}
		}

	}

	.pr-footer {
		padding: 0px 15px;
		margin-bottom: 15px;
	}

	&.pr-toc {
		display: flex;
		// flex: 1;
		height: fit-content;
		max-height: 800px;
		font-size: 12px;

		h1 {
			font-size: 2em;
		}

		h2 {
			font-size: 1.8em;
		}

		h3 {
			font-size: 1.6em;
		}

		h4,
		h5,
		h6 {
			font-size: 1.4em;
		}

		p {
			font-size: 1.2em;
		}
	}
}