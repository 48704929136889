.side-navigation-container {
	width: 0px;
	min-height: 98vh;
	display: flex;
	flex-direction: column;
	z-index: 1;
	position: absolute;
	transition: 0.5s;
	justify-content: space-between;
	padding-bottom: 20px;
	padding-top: 10px;
	margin-top: 40px;

	.side-nav-container {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		width: 100%;
		box-sizing: border-box;
		align-items: center;
		max-height: 80vh;

		.btn-menu-sidenav {
			cursor: pointer;
			display: none;
			// width: 150px;
			width: 0px;
			height: 50px;
			padding-left: 20px;
			align-items: center;
			transition: width 2s linear 2s;

			.button-icon-container {
				display: flex;
				height: 25px;
				width: 25px;
				line-height: 25px;
				color: grey;
				justify-content: center;
				align-items: center;
				margin-right: 5px;
			}

			.btn-text {
				color: #474747;
				font-size: 14px;
			}

			&:hover {
				background-color: var(--afternoon);

				.button-icon-container {
					color: white !important;
				}

				.btn-text {
					color: white;

				}
			}
		}

		.brk {
			width: 90%;
			height: 2px;
			background-color: #eeeeee;
		}
	}

	.version-content {
		height: 30px;
		margin: 10px 6px 0px 0px;
		outline: 0 !important;
		width: 100%;
		text-align: center;
		display: none;
		font-size: var(--h6);
	}

}