@import '../../assets/css/color-variable.scss';

.view-profile-cell {
    border-right: 10px solid var(--primary-color);
    border-radius: 0px 10px 10px 0;
    text-align: center;
    border-left: 2px solid var(--background-color);
    cursor: pointer;

    a {

        text-decoration: none;
    }

    .view-profile-text {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--text-dark-grey);
    }

    .view-profile-icon {
        display: none;
        height: 50px;
        width: 100%;
    }

}

.customer-image {
    width: 50px;
}

.customer-id {
    width: 50px;
}

.customer-name {
    width: 22%;
}

.customer-phone {
    width: 12%;
}

.customer-email {
    width: 27%;
}

.customer-ratings {
    width: 10%;
}

.customer-action {
    width: 75px;
}



@media only screen and (max-width: 1280px) {
    .customer-action {
        width: 40px;
    }

    .view-profile-cell {
        width: 2%;

        .view-profile-text {
            display: none;
        }

        .view-profile-icon {
            margin-right: 2px;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                color: #523563;
            }
        }
    }

}