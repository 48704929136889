:root {
	/* Colors: */
	--veg: #a9ce7b;
	--meat: #f09e9b;
	--text-dark-grey: #474747;
	--primary-color: #523563;
	--secondary-color: #73528c;
	--text-midgray: #9d9d9d;
	--lighter-_for-borders: #fafaff;
	--signin-button-shadow-light: #c3a4dbb3;
	--background-color: #f8f8fc;
	--highlight-grey: #909090;
	--text-light-grey: #d8d8d8;
	--highlight-green: #52be7f;
	--backgroung-color: #f8f8ff;
	--highlight-red: #d35a47;
	--highlight-orange: #ec8039;
	--shadow-color-dark: #1c1222;
	--shadow-color-dark-rgba: rgba(22, 49, 30, 0.2);
	--shadow-color-light: #fff;
	--gradient-background-one: #f7f7fa;
	--gradient-background-two: #f4f4f8;
	--menu-icon-green: #94c97d;
	--width: 100px;
	--venue-10: #e78106;
	--venue-9: #0690a7;
	--venue-8: #ab488b;
	--venue-7: #7152bc;
	--venue-6: #6d5855;
	--venue-5: #12235a;
	--venue-4: #88a3d3;
	--venue-3: #87ccd5;
	--venue-2: #c3c382;
	--venue-1: #cd78e2;

	--morning: #fbd359;
	--afternoon: #3498db;
	--evening: #523563;

	--payment: #52be7f;
	--refund: #d35a47;
	--void: #ab488b;
	--discount: #ec8039;

	--update-highlight: rgba(217, 248, 252, 0.5);
	--hover-highlight: rgba(217, 248, 252);
	// --hover-highlight: #b0edf5;

	// --canceled: #D35A47;
	// --complete: #393939;
	// --pending: #FF8774;
	// // --paid: #2ECC71;
	// --confirmed: #2ECC71;
	// --advance: #82D8B5;
	// --booked: #82D8B5;
	// --booking: #6B91AC;
	// --process: #E2B60C;
	// // --booked: #E2B60C;
	// --inquiry: #FF8774;
	// --hold: #A0A0A0;
	--canceled: #a0a0a0;
	--complete: #393939;
	--pending: #ff8774;
	--confirmed: #2ecc71;
	--advance: #82d8b5;
	--booked: #82d8b5;
	--booking: #6b91ac;
	--process: #e2b60c;
	--inquiry: #e8d392;
	--hold: #a0a0a0;
}
