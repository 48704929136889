.label-container {
	width: 100%;
	padding-top: 10px;

	.clickable-types {
		display: flex;
		overflow-x: auto;
		overflow-y: hidden;
		border: 1px solid lightgray;
		margin: 0 5px;
		box-sizing: border-box;

		&::-webkit-scrollbar {
			display: none;
		}

		.menu-label-button {
			display: flex;
			flex: 1;
			height: 40px;
			min-width: 100px;
			align-items: center;
			justify-content: center;
			padding: 0 10px;
			border-right: 1px dashed lightgray;
			box-sizing: border-box;
			cursor: pointer;

			.label-menu-name {
				display: flex;
				min-width: 55px;
				height: 30px;
				line-height: 12px;
				font-size: 12px;
				align-items: center;
				justify-content: center;
				text-transform: capitalize;
				// border: 1px solid red;
			}

			.label-menu-count {
				display: flex;
				width: 20px;
				height: 20px;
				min-width: 10px;
				align-items: center;
				justify-content: center;
				background-color: var(--afternoon);
				color: white;
				font-size: 9px;
				margin-left: 10px;
				border-radius: 5px;
				font-weight: bold;
			}

			&.with-val {
				// background-color: green;
				display: flex;
			}

			&.displayed {
				border-bottom: 4px solid var(--afternoon);
				color: var(--afternoon);
				font-weight: bold;
			}

			&:hover {
				border-bottom: 4px solid var(--afternoon);
				color: var(--afternoon);
				font-weight: bold;
			}
		}
	}

	.menu-filter-search {
		display: flex;
		justify-content: end;
		// border: 1px solid red;
		margin: 10px;

		>input {
			height: 30px;
			width: 150px;
			padding: 5px 10px;
			font-size: 14px;
			margin-right: 10px;
			border-radius: 5px;
			border: 1px solid lightgray;
			background: transparent;
		}

		>button {
			width: 80px;
			height: 30px;
			border-radius: 5px;

			&:hover {
				background: var(--afternoon);
				color: white;
			}
		}
	}
}

.menu-display {
	display: flex;
	height: 400px;
	overflow-y: auto;
	width: 99%;
	justify-content: center;
	padding: 10px 10px;

	.menu-display-items {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		gap: 10px;
		padding: 2px;
		height: fit-content;

		.empty-items {
			display: flex;
			width: 100%;
			height: 100px;
			justify-content: center;
			align-items: center;
			color: Lightgrey;
		}
	}

	&::-webkit-scrollbar {
		display: none;
	}
}

.menu-save {
	display: flex;
	background: var(--confirmed);
	color: white;
	font-weight: bold;
	font-size: 16px;
	border-radius: 10px;
	margin: 0px 10px;
	// width: 100%;
	height: 40px;
	justify-content: center;
	align-items: center;

	&:hover {
		cursor: pointer;
	}
}