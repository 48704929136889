.progress-wrapper {
    margin-bottom: 15px;
   

    p{
        font-size: 12px;
        line-height: 12px;
        // border: 1px dashed blue;
        margin-bottom: 10px;
    }

    .progressbar-wrapper {
        position: relative;
        height: 30px;
        width: 100%;
        // background-color: white;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 2px;

        .progressbar {
            position: absolute;
            height: inherit;
            text-align: right;
            line-height: 30px;
            color: white;
            // padding: 5px 5px;
            padding-right: 10px;
            box-sizing: border-box;
            justify-items: center;
           
            &.right{
                right: 0px;
            }
        }
        p{
            position: absolute;
            right: 10px;
            line-height: 30px;
            font-size: 16px;
            border: 1ps solid red;
            color:black;
            
            &.right{
                left: 10px;
                right: auto;
            }
        }
        
    }
    .segment-block{
        display:flex;
        position:relative;
        width: 100%;

        div{
            height: 10px;
            font-size: 10px;
            border-left: 1px solid grey;
        }
    }
}