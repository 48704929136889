.switch-user-wrapper {
	width: 100%;
	height: 90vh;
	display: flex;
	justify-content: center;
	align-items: center;

}

.switch-user-form-wrapper {
	min-width: fit-content;
	width: 650px;
	min-height: 200px;
	border-radius: 10px;
}

.switch-user-button-wrapper {
	width: 600px;
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.company-list-container {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;

}

.company-list-wrapper {
	width: 135px;
	height: 119px;
	padding: 10px;
	cursor: pointer;
	margin: 5px 0px 15px 20px;
	border: 2px var(--text-light-grey) solid;
	border-radius: 10px;

	&.selected {
		background: var(--primary-color);

		* {
			color: white !important;
		}

	}

	.company-name-wrapper {
		font-size: 14px;
		border-bottom: 1px solid lightgray;
		margin-bottom: 5px;
	}

	.company-address-wrapper,
	.company-role-wrapper {
		text-transform: capitalize;
		font-size: 12px;
		color: var(--highlight-grey);
	}
}