@import "../../../assets/css/color-variable.scss";
@import "../../../assets/css/main.scss";

$color-1: var(--shadow-color-dark-rgba);

.ck.ck-editor {
	max-height: 200px;
	display: flex;
	flex-flow: column nowrap;
	overflow: hidden;
	overflow-y: scroll;
	border: 1px solid grey !important;
	border-radius: 10px;
	box-sizing: border-box;

	>.ck-editor__main>.ck-editor__editable {
		background: transparent !important;
		// border: none !important;
	}

	&::-webkit-scrollbar {
		display: none !important;
		// width: 10px;

	}

	// /* Track */
	// ::-webkit-scrollbar-track {
	// 	background: #f1f1f1;
	// }

	// /* Handle */
	// ::-webkit-scrollbar-thumb {
	// 	background: #888;
	// }

	// /* Handle on hover */
	// ::-webkit-scrollbar-thumb:hover {
	// 	background: #555;
	// }

}

.company-setting-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.venue-section-title {
	color: var(--text-dark-grey);
	text-align: center;
	font-size: 18px;
}

.company-name-container {
	height: 45px;
	width: 285px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
	z-index: -1;
}

.venue-initial-content {
	height: 150px;
	text-transform: uppercase;
}

.venue-container {
	width: 285px;
	margin-bottom: 5px;
	display: flex;
	// justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 5px;
	// position: relative;
}

.venue-logo-container {
	height: 200px;
	width: 285px;
	margin-bottom: 5px;
	border-radius: 10px 10px 0px 0px;
	color: var(--text-light-grey);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 200px;
	cursor: pointer;
	line-height: 200px;
	overflow: hidden;

	>img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.venue-title {
	color: var(--text-dark-grey);
	font-size: 18px;
}

.add-venue-button {
	// position: absolute;
	margin: 15px 0px 15px 0px;
}

.company-details-container {
	width: 940px;
	min-height: 200px;

	.detail-container {
		display: flex;
		flex-direction: row;
		margin-bottom: 10px;
	}
}

.subscription-wrapper {
	width: 226px;
	height: 200px;
	margin-right: 20px;
	padding: 20px 14px 20px 20px;
}

.payment-method-wrapper {
	width: 472px;
	height: 200px;
	margin-right: 20px;
	padding: 20px 14px 20px 20px;
}

.payment-history-wrapper {
	width: 226px;
	height: 200px;
	margin-right: 20px;
	padding: 20px 14px 20px 20px;
}

.subscription-title {
	color: var(--text-dark-grey);
	font-size: 14px;
}

.subscription-description {
	color: var(--text-midgray);
	font-size: 12px;
}

.payment-radio {
	display: block;
	line-height: 24px;
	width: 186px;
	height: 24px;
	border-radius: 5px;
	color: var(--text-midgray);
	text-align: left;
	font-size: 12px;
	padding-left: 5px;
	cursor: pointer;

	&.selected {
		color: var(--background-color);
		background-color: var(--highlight-green);
	}
}

.venue-color-checkbox[type="radio"] {
	display: none;
}

.venue-checkbox-container {
	display: flex;
	position: relative;
	width: 230px;
	height: 24px;
	cursor: pointer;
	font-size: 14px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	line-height: 24px;
	padding: 0px 4px;
	justify-content: space-between;
	margin: 10px;
	border-radius: 5px;
	height: 30px;

	&.viewer {
		opacity: 0.4;
		cursor: auto;
	}

	&.modal {
		min-width: 120px;
		max-width: 250px;
		cursor: auto;
	}
}

.venue-checkbox-label {
	position: absolute;
	left: 30px;
}

.venue-checkbox-input {
	display: none;
}

/* Create a custom checkbox */
.venue-checkmark {
	position: relative;
	height: 16px;
	width: 16px;
	padding: 3px;
	box-sizing: border-box;
	border-radius: 50%;
	margin-top: 4px;
}

.venue-checkbox-container .venue-checkbox-input:checked {
	box-shadow: -1px -2px 4px #fff, 1px 2px 4px rgba(28, 18, 34, 0.2);
}

/* Show the checkmark when checked */
.venue-checkbox-input:checked~.venue-checkmark::after {
	display: block;
}

/* Style the checkmark/indicator */
.venue-checkbox-container .venue-checkmark::after {
	content: "";
	width: 10px;
	height: 10px;
	background-color: #52be7f;
	border-radius: 50%;
}

/* scss for company settings panel */

.id-details-container {
	height: 119px;
	width: 135px;
	border-radius: 10px;
	position: relative;

	.id-details-title {
		position: absolute;
		top: 15px;
		right: 0;
		left: 0;
		margin: auto;
		text-align: center;
		font-size: 12px;
		color: var(--text-dark-grey);
	}

	.id-details-message {
		position: absolute;
		width: 30px;
		height: 30px;
		text-align: center;
		font-size: 26px;
		top: 59px;
		right: 0;
		left: 0;
		margin: auto;
		color: var(--text-midgray);
		display: flex;
		justify-content: center;
		align-items: center;

		&.status {
			width: 75px;
		}

		.verified {
			color: var(--highlight-green);
		}

		.unverified {
			color: var(--highlight-red);
		}
	}
}

.toggle-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;

	.toggle-container {
		display: flex;
		flex-direction: row;
		position: relative;
		margin-top: 10px;
		height: 30px;

		/* css while halls are not editable */
		.static-halls-container {
			width: 70px;
			height: 30px;
			margin-right: 15px;
			text-align: center;
			line-height: 30px;

			color: var(--shadow-color-light);
			border-radius: 5px;
			box-shadow: -2px -2px 4px var(--shadow-color-light),
				2px 2px 4px $color-1;

			&.inactive {
				background-color: var(--highlight-red);
			}

			&.active {
				background-color: var(--highlight-green);
			}
		}

		/* css while halls are editable */

		.halls-wrapper {
			display: flex;
			flex-direction: row;

			.halls-container {
				width: 70px;
				height: 30px;
				background-color: #d35a47;
				border-radius: 5px;
				margin-right: 15px;
			}

			.halls-content-label {
				background-color: #d35a47;
				line-height: 30px;
				width: 70px;
				height: 30px;
				color: white;
				border-radius: 5px;
				text-align: center;
				box-shadow: -2px -2px 4px var(--shadow-color-light),
					2px 2px 4px $color-1;
			}

			.hall-checkbox[type="checkbox"] {
				display: none;
			}

			.hall-checkbox:checked+.halls-content-label {
				background-color: #52be7f;
				border-radius: 5px;
			}
		}

		.add-hall-container {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: 10px;
			height: 30px;
			width: 30px;
			position: absolute;
			right: 0;
			margin: auto;

			.add-hall-button {
				@extend .button-wrapper;
				width: 24px;
				height: 24px;
				border-radius: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}

.sec-fe {
	display: flex;
	flex-direction: column;
	min-width: 135px;
	justify-content: center;
	align-items: center;
	// border: 1px solid red;

	.sec-fe-wrapper {
		display: flex;
		gap: 20px;
		min-height: 100px;
		flex-wrap: wrap;
		justify-content: space-evenly;

		.sec-fe-container {
			display: grid;
			grid-template-rows: 30px 1fr 40px;
			width: 285px;
			padding: 10px;
			border-radius: 10px;

			.sec-fe-title {
				display: grid;
				grid-template-columns: 30px 1fr 30px;
				justify-content: center;

				>p {
					font-size: 14px;
					font-weight: bold;
					text-align: center;
					padding: 3px;
					margin-bottom: 0px;
				}

				>i {
					font-size: 16px;
					text-align: left;
					padding: 6px 6px;
				}
			}

			.sec-fe-sub {
				font-size: 12px;
				padding: 4px;
			}

			>button {
				border-radius: 10px;
				background-color: white;
			}
		}


	}

	.switch {
		>i {
			color: var(--afternoon);
		}

		>button {
			&:hover {
				background: var(--afternoon);
				color: white;
			}
		}
	}

	.close-shop {
		>i {
			color: var(--booked);
		}

		>button {
			&:hover {
				background: var(--booked);
				color: white;
			}
		}
	}

	.change-pin {
		>i {
			color: var(--pending);
		}

		>button {
			&:hover {
				background: var(--pending);
				color: white;
			}
		}
	}

	.deactivate {
		>i {
			color: #d35a47;
		}

		>button {
			&:hover {
				background: #d35a47;
				color: white;
			}
		}
	}
}