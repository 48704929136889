@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	font-family: 'SofiaProMedium';
}

body {
	margin: 0;
	font-family: "SofiaProMedium", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* max-height: 1080px; */
}

code {
	font-family: Menlo, Monaco, Consolas, "Courier New", monospace, "SofiaProMedium";
}


@font-face {
	font-family: "SofiaProMedium";
	src: local("SofiaProMedium"), url('./assets/fonts/SofiaPro/sofiapro-medium.ttf') format("truetype");
}

/* width */
::-webkit-scrollbar {
	width: 8px;
	margin-left: 4px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #D8D8D8;
	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

input[type=email] {
	text-transform: none !important;
}

input[type=date] {
	text-transform: lowercase !important;
}

/* input[type=text] {
	text-transform: capitalize !important;
} */

input[type=password] {
	text-transform: none !important;
}