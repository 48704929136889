.venue-color-select-wrapper {
    height: 30px;
    position: relative;

    &.registration {
        height: 52px;
        width: 135px;
    }

    .venue-color-select-button {
        height: 30px;
        display: flex;
        padding: 10px;
        font-size: 16px;
        text-align: center;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border: 0;
        outline: 0;
        border-radius: 5px;

        &.registration {
            margin-top: 22px;
        }


    }
}

.venue-color-option-wrapper {
    height: 85px;
    padding: 0px 5px 5px 5px;
    margin-top: 5px;
    border-radius: 5px;

    &.registration {
        height: 130px;
        width: 135px;
    }

    .venue-color-picker-label {
        font-size: 10px;
        height: 10px;
    }

    .venue-color-selector-wrapper {
        height: 60px;
        width: 250px;
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        row-gap: 5px;

        &.registration {
            width: 135px;
            grid-template-columns: auto auto auto;
            height: 100px;
        }

        .venue-color-select-container {
            position: relative;

            .color-selector-label {
                position: absolute;
                height: 20px;
                width: 40px;
                cursor: pointer;
                border-radius: 5px;

                &.registration {
                    width: 36px;
                }
            }

            .venue-color-checkbox[type=radio] {
                display: none;
            }

            .venue-color-checkbox:checked+.color-selector-label {

                border: 2px solid #523563;
                padding: 2px;
                border-radius: 5px;

            }
        }

    }
}