.delete-booking-wrapper {
	width: 100%;
	// min-height: 40px;
	background: var(--highlight-red) 0% 0% no-repeat padding-box;
	border-radius: 5px;
	margin-top: 20px;
	padding: 8px 20px;
	color: var(--background-color);
	cursor: pointer;

	&.canceled {
		background: var(--highlight-orange) 0% 0% no-repeat padding-box;
	}

	&.reopen {
		background: var(--highlight-green) 0% 0% no-repeat padding-box;
	}

	&.transfer {
		background: var(--afternoon) 0% 0% no-repeat padding-box;
	}

	label {
		cursor: pointer;
	}

}

.change-status-button-wrapper {
	width: 100%;
	// min-height: 40px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 10px 0px;
	gap: 10px;

	.change-status-button {
		width: 250px;
		height: 40px;
		border-radius: 40px;
		margin-top: 10px;

		&.prev-inquiry,
		&.next-inquiry,
		&.prev-booked {
			opacity: 0.5;
			cursor: not-allowed;
		}

	}

}

@media only screen and (max-width: 720px) {

	.change-status-button-wrapper {
		display: flex;
		flex-direction: column !important;
	}

	.delete-booking-wrapper {

		display: flex;
		flex-direction: column !important;
		text-align: center;
	}
}