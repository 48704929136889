.s-modal-wrapper {
	position: fixed;
	top: 45px;
	right: -100%;
	width: 100%;
	height: calc(100% - 45px);
	z-index: 1; // parent position relative, z-index: 0
	display: flex;
	box-sizing: border-box;
	font-size: 12px;

	.s-modal_overlay {
		cursor: pointer;
		width: 100%;
		height: 100%;
		background-color: #27272733;
		opacity: 0;
		transition: all 400ms ease-in-out;
	}

	.s-modal-container {
		flex: 1 0 70%;
		width: 50%;
		position: absolute;
		height: 100%;
		display: grid;
		grid-template-rows: 60px 1fr 20px;
		top: 0;
		right: -100%;
		transition: right 400ms ease-in-out;
		background-color: #f8f8fc;
		border-top-left-radius: 16px;
		// border-bottom-left-radius: 16px;
		overflow: hidden;
		z-index: 1;
	}

	.s-modal-checkbox-container {
		display: flex;
		gap: 20px;
		margin-bottom: 10px;

		.s-modal-checkbox {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			font-size: 12px;

			>label {
				margin-top: 2px;
			}
		}
	}


	&.expanded {
		right: 0;

		.s-modal_overlay {
			opacity: 1;
		}

		.s-modal-container {
			right: 0;
		}
	}

	.s-modal-headder {
		display: flex;
		height: 60px;
		font-size: 16px;
		align-items: center;
		justify-content: space-between;
		padding: 15px 15px 5px 15px;
		border-bottom: 1px solid #1c1222;

		.s-headder-title {
			display: flex;
			height: 30px;
			align-items: end;
			// border: 1px solid red;

		}

		.s-modal-header-items {
			display: flex;
			align-items: center;
		}

		.header-action-button {
			font-size: 1em;
			white-space: nowrap;
			color: var(--background-color);
			background-color: var(--afternoon);
			padding: 4px 16px;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 8px;
			box-shadow: -1px 1px 4px rgba(22, 49, 30, 0.2),
				1px -1px 4px #fcfcff;
			border-radius: 4px;
			transition: all 200ms ease-in-out;

			i {
				margin-top: -2px;
			}

			&:hover {
				box-shadow: none;
				transform: scale(0.95);
			}
		}
	}

	.s-buttons-wrapper {
		display: flex;
		justify-content: end;
		gap: 10px;
		margin: 10px;
		height: 30px;
		// border: 1px solid red;


		.s-button {
			display: flex;
			min-width: 100px;
			width: auto;
			height: 30px;
			align-items: center;
			justify-content: center;
			color: var(--text-dark-grey);
			font-size: 14px;
			border-radius: 5px;
			box-shadow: 2px 2px 6px #00000030;

			&.iconic {
				min-width: 30px;
				// width: 30px;
				gap: 5px;
				line-height: 30px;
				padding: 0px 5px;
			}

			&.action {
				color: white;
				background-color: var(--afternoon);
				opacity: 0.8;
			}

			&:hover {
				cursor: pointer;
				color: white;
				opacity: 1;
				background-color: var(--afternoon);
			}
		}

	}

	.s-modal-body {
		width: 100%;
		height: 100%;
		overflow-y: auto;
		padding: 10px 10px 0px 10px;
		// border: 1px solid red;


		.s-preview-container {
			min-width: 118px;
			width: 100%;
			height: 400px;
			position: relative;
			padding: 4px;
			background-color: #00000012;
			// border: 1px solid red;

			&.pay-receipt {
				height: 300px;
			}

			.s-preview-container-inner {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) scale(0.6);
				background-color: #fcfcff;
				border: 4px solid #d8d8d877;
				transition: all 300ms ease-in-out;
				cursor: pointer;
				z-index: 0;
				font-size: 18px; // for the templates preview
			}
		}

		.s-form {
			display: flex;
			padding: 10px;
			justify-content: space-around;
			flex-wrap: wrap;
		}

		.booking-date-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 16px;
			padding: 16px;

			.booking-date {
				font-size: 16px;
				font-weight: 500;
				color: #9d9d9d;
			}

		}

		.s-data-fix-wrapper {
			// border: 1px solid green;
			padding: 8px;

			.time-header {
				background-color: var(--evening);
				color: #fff;
				font-size: 16px;
				font-weight: bold;
				text-transform: uppercase;
				padding: 8px 16px;
				margin-bottom: 16px;
				border-radius: 4px;

				&.morning {
					background-color: var(--morning);
					// color: #9d9d9d;
				}

				&.afternoon {
					background-color: var(--afternoon);
				}

			}

			.s-booking-container {
				display: flex;
				gap: 16px;
				margin-bottom: 16px;
				flex-wrap: wrap;

				.s-booking {
					display: flex;
					flex: 1;
					max-width: 50%;
					flex-direction: column;
					border-radius: 8px;
					padding: 16px;
					box-shadow: 2px 2px 6px #00000030;
					// grid-template-columns: 60px repeat(5, minmax(10px, 1fr));

					.col-style {
						display: flex;
						flex: 1;
						gap: 16px;

						font-size: 16px;
						text-transform: capitalize;

					}

					&.strikeout {
						text-decoration: line-through;
						background-color: grey;
					}

					.s-label {
						font-size: 16px;
						color: #9d9d9d;
					}
				}
			}
		}

		.s-booking-button-wrapper {
			background-color: #f8f8fc;
			position: sticky;
			bottom: 0px;
			display: flex;
			justify-content: flex-end;
			padding: 8px;

			.s-booking-button {
				padding: 8px 16px;
				border-radius: 8px;
				background-color: #3498db;
				color: #fff;
				font-size: 16px;
				cursor: pointer;
				box-shadow: 2px 2px 6px #00000030;
				transition: all 300ms ease-in-out;

				&:hover {
					transform: scale(0.9);
				}
			}
		}
	}

	.s-modal-body-ana-breakdown-wrapper {
		width: 100%;
		height: fit-content;
		overflow-y: auto;
		padding: 10px 10px 0px 10px;

		.s-modal-body-ana-breakdown {
			display: flex;
			align-content: flex-start;
			flex-wrap: wrap;

			.breakdown-block {
				display: flex;
				flex-direction: column;
				flex: 1;
				// height: 100px;
				min-width: 200px;
				margin-bottom: 10px;
				// border: 1px solid red;

				&.sub-text {
					font-size: 12px;
					// padding-top: 5px;
				}

				.breakdown-title {
					font-size: 12px;
				}

				.breakdown-subtitle {
					font-size: 12px;
				}

				.breakdown-data {
					font-size: 16px;
					font-weight: bold;
				}

				.alert-warn {
					color: var(--highlight-red);
				}

			}
		}

		.breakdown-blocked {
			background-color: var(--afternoon);
			opacity: 0.5;
			padding: 5px 10px;
			font-size: 9px;
			color: white;
			font-weight: bold;
			border-radius: 5px;

			>i {
				padding-right: 10px;
			}
		}
	}
}

@media only screen and (max-width: 1080px) {
	.s-modal-wrapper {
		font-size: 10px;

		.s-modal-container {
			flex: 1 0 50%;
			grid-template-rows: 60px 1fr 20px;
		}

		.s-modal-headder {
			font-size: 12px;
			padding: 10px 10px 5px 10px;

			.s-headder-title {
				display: flex;
				height: 30px;
				align-items: center;
				// border: 1px solid red;

			}

		}

		.s-buttons-wrapper {
			.s-button {
				min-width: 80px;
				font-size: 12px;
			}

		}

		.s-modal-body {
			.s-preview-container {
				min-width: 118px;
				width: 100%;
				height: 400px;
				position: relative;
				padding: 4px;
				background-color: #00000012;

				&.pay-receipt {
					height: 150px;
				}

				.s-preview-container-inner {
					transform: translate(-50%, -50%) scale(0.34);

				}
			}
		}
	}
}