.data-display-wrapper {
	display: flex;
	flex: 1;
	border-radius: 10px;
	min-width: 135px;
	font-size: 12px;
	// border: 1px solid;

	&.sm {
		width: 135px;
	}

	&.l {
		width: 285px;
	}

	.data-display-container {
		width: 100%;


		&.sm {
			display: flex;
			flex-direction: column;
			padding: 10px;
			justify-content: space-between;
			font-size: 1em;

		}

		&.l {
			display: flex;
			flex-direction: column;
			padding: 10px 15px;
			justify-content: space-between;
			font-size: 1.5em;

		}

		// &>div {
		// 	border: 1px solid;
		// }

		:first-child {
			font-weight: bold;
			border-radius: 5px;
			padding: 5px 10px;
			margin-bottom: 5px;
		}

		:nth-child(2) {
			// border: 1px solid;
		}

		:last-child {
			font-size: 1.5em;
			// border: 1px solid;
		}
	}
}

@media only screen and (min-width: 1000px) {
	.data-display-wrapper {
		font-size: 14px;
	}

}