@import "../../../assets/css/color-variable.scss";
@import "../../../assets/css/size-variable.scss";

/* form text input */
.form-input-wrapper {
	display: flex;
	flex-direction: column;
	margin-bottom: 1px;
	// background-color: red;

	.form-input-label {
		font-size: var(--h6);
		color: var(--text-dark-grey);
		margin-bottom: 8px;
	}

	.form-input {
		padding: 0 10px;
		width: 285px;
		height: 30px;
		background-image: linear-gradient(107deg, #f7f7fa, #f4f4f8);
		box-shadow: inset 1px 2px 4px rgba(28, 18, 34, 0.1),
			inset -2px -2px 4px rgb(255, 255, 255);
		border-radius: 5px;
		border: 0;
		outline: 0;
		color: var(--primary-color);
		font-size: var(--h5);

		&.password {
			position: absolute;
		}

		&.form-md {
			width: 135px;
		}

		&.form-notes {
			width: 285px;
			height: 110px;
		}

		&.form-notes-md {
			width: 285px;
			height: 95px;
		}

		&.form-notes-sm {
			width: 285px;
			height: 45px;
		}

		&:disabled {
			opacity: 0.4;
		}

		&::placeholder {
			/* Chrome, Firefox, Opera, Safari 10.1+ */
			color: var(--highlight-grey);
			opacity: 0.4;
			// text-transform: capitalize;
			/* Firefox */
		}

		&:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			color: var(--highlight-grey);
			opacity: 0.4;
			// text-transform: capitalize;
		}

		&::-ms-input-placeholder {
			/* Microsoft Edge */
			color: var(--highlight-grey);
			opacity: 0.4;
			// text-transform: capitalize;
		}
	}

	.form-validation-error {
		color: var(--highlight-red);
		font-size: var(--h7);
		text-align: right;
		height: 15px;
	}

	.password-container {
		position: relative;
		width: 285px;
		height: 30px;

		.password-visibility {
			height: 30px;
			width: 30px;
			position: absolute;
			right: 0;
			text-align: center;
			line-height: 30px;
			// z-index: 99;
		}
	}
}

/* form text input ends */

/* form input css with bottom border */

.text-input-container {
	display: flex;
	flex-direction: column;
	max-height: 67px;

	.input-label {
		font-size: var(--h6);
		// color: var(--text-midgray);
		margin-bottom: 8px;
	}

	.icon-input-content {
		display: flex;
		margin-top: 5px;

		.input-icon {
			height: 30px;
			width: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&.icon {
		height: 30px;
		border: none;
		margin-bottom: 5px;
	}

	&.search {
		height: 30px;
	}

	&.servicePrice {
		height: 15px;
	}

	&.servicePrice {
		height: 15px;
	}

	.text-input {
		background: none;
		font-size: 14px;
		border: none;
		outline: none;
		height: 30px;
		text-transform: capitalize;
		// background-color: #523563;

		&::placeholder {
			color: var(--text-midgray);
			text-transform: capitalize;
		}

		&:disabled {
			opacity: 0.4;
		}

		&.edit {
			border-bottom: 2px solid #e0e0e0;
		}

		&.form-md {
			width: 135px;
		}

		&.form-lg {
			width: 285px;
		}

		&.form-xs {
			width: 80px !important;
		}

		&.form-xxs {
			width: 30px !important;
		}

		&.icon-lg {
			width: 250px;
			text-transform: lowercase !important; //for web url and social media
		}

		&.npdates {
			width: auto;
			text-align: center;

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		}

		&:focus-within {
			border-bottom: 2px solid #523563;
		}

		&:focus {
			outline: none;
		}
	}

	.validation-error {
		font-size: 9px;
		height: 10px;
		float: right;
		margin-top: 5px;
	}
}