.complete-status {
	display: flex;
	width: 285px;
	height: 40px;
	border-radius: 10px;
	color: white;
	padding: 0px 15px;
	justify-content: center;
	align-items: center;
	background-color: var(--primary-color);

	p {
		line-height: 40px;
		width: fit-content;
		margin: 0px;
	}

	&:hover {
		cursor: pointer;
		background-color: var(--secondary-color);
		// p{
		//     animation: hello 1s linear 1 forwards;
		// }

		// @keyframes hello {
		//     0% { transform: translateX(0px)}
		//     100% { transform: translateX(-80px)}
		// }
	}
}

.status-display {
	display: flex;
	width: 285px;
	height: 40px;
	border-radius: 10px;
	padding: 0px 15px;
	justify-content: center;
	align-items: center;
	margin-bottom: 15px;

	&>p {
		margin: 0px;
		line-height: 40px;
		width: fit-content;
		cursor: pointer;
		color: var(--highlight-red);
	}
}

.queue-booking {
	height: 30px;
	display: grid;
	grid-template-columns: 1fr 30px;
	gap: 10px;
	margin-bottom: 15px;
	align-items: center;

	.q-button {
		background-color: red;
	}
}

.q-data {
	height: 210px;
	overflow-y: auto;

	.q-row {
		height: 30px;
		display: grid;
		grid-template-columns: 1fr 50px 50px;
		margin-bottom: 5px;
		overflow: hidden;
		font-size: 14px;

		>div {
			display: flex;
			align-items: center;
			text-transform: capitalize;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding-left: 5px;
		}

		&:hover {
			background-color: var(--afternoon);
			color: white;
			cursor: pointer;
		}
	}

	&::-webkit-scrollbar {
		width: 5px;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: var(--secondary-color);
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: var(--primary-color);
	}
}

.q-head {
	font-size: 11px;
	color: grey;
	height: 13px;
	display: grid;
	grid-template-columns: 1fr 50px 50px;
	margin-bottom: 5px;

	>div {
		display: flex;
		align-items: center;
	}
}

.view-vat-wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 285px;
	height: 285px;
	padding: 10px;
	border-radius: 10px;

	>* {
		margin-bottom: 10px;
	}

	p {
		margin: 0px 0px;
	}

	.view-title {
		display: grid;
		grid-template-columns: 1fr 80px;
		margin-bottom: 15px;
		align-items: center;

		.view-addvat-button {
			display: flex;
			position: relative;
			height: 30px;
			border-radius: 10px;
			justify-content: center;
			align-items: center;
			gap: 10px;
			background-color: var(--primary-color);
			color: white;
			font-size: 12px;
		}
	}

	.view-status-block {
		display: grid;
		grid-template-columns: 1fr 20px;
		width: 265px;
		height: 30px;
		border-radius: 10px;
		align-items: center;
		padding: 5px 10px;
		font-size: 12px;


		&.vat {
			background-color: var(--primary-color);
			color: white;

			&:hover {
				cursor: pointer;
			}
		}

	}

	.cost-block {
		display: grid;
		grid-template-columns: 1fr 30px 70px;
		font-size: 12px;
		color: var(--text-midgray);
		margin-bottom: 10px;

		.cost-block-amounts {
			text-align: right;

			&.focus {
				color: var(--text-dark-grey);
			}
		}
	}

}