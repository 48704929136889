.analytics-filter-container {
	max-width: 600px;
}

.chart-wrapper {
	position: relative;
	width: 100%;
	height: 600px;
	padding: 15px;
	// border: 1px solid red;//
}

@media only screen and (max-width: 945px) {

	/* new main innner contaner css for new design starting from company settings */
	.chart-wrapper {
		height: 400px;
	}
}

.ana-temp {
	display: flex;
	flex: 1;
	height: 50vh;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	>i {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50px;
		width: 50px;
		border-radius: 5px;
		box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.498);
		background-color: var(--afternoon);
		color: white;
		margin-bottom: 20px;
	}

	>p {
		color: var(--highlight-grey);
	}

}


.ana-wrapper {
	display: grid;
	grid-template-columns: 1fr 3fr;
	box-sizing: border-box;
	position: relative;
	font-size: 12px;
	width: 100vw;
	margin-top: 40px;
	height: calc(100vh - 40px);

	&::-webkit-scrollbar {
		display: none;
	}

	.w-box {
		// background-color: #f1f1fc;
		background-color: #f8f8fc;
		box-shadow: inset 5px 5px 8px 0px #ffffff, 6px 4px 8px 0px #e7e7e7;
		padding: 24px;
		border-radius: 16px;
	}

	.ana-empty-container {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		// justify-content: center;
		padding-top: 32px;
		gap: 24px;
		font-size: 1em;

		img {
			width: 120px;
			object-fit: contain;
		}

		.empty-message {
			font-size: 1.32em;
			color: #523563;
			font-weight: 600;
			text-align: center;
		}
	}

	.ana-left-wrapper {
		overflow: hidden;
		padding: 48px 32px;

		.ana-left-container {
			height: 100%;
			display: flex;
			flex-direction: column;
			gap: 16px;
		}

		.ana-section-container {
			display: flex;
			flex-direction: column;
			gap: 8px;
			border-radius: 16px;
			background-color: #f1f1fc;
			padding: 16px;

			.section-title {
				font-size: 1.64em;
				color: #272727;
				font-weight: 800;
				margin-bottom: 8px;
			}

			.section-description {
				font-size: 1.1em;
				color: #9d9d9d;
				font-weight: 500;
				margin: 0;
			}
		}

		.crm-list-container {
			display: flex;
			flex-direction: column;
			gap: 8px;
			overflow-y: scroll;
			overflow-x: hidden;

			&::-webkit-scrollbar {
				width: 8px;
			}

			&::-webkit-scrollbar-track {
				box-shadow: inset 0 0 6px #9d9d9d;
				border-radius: 4px;
			}

			&::-webkit-scrollbar-thumb {
				background-color: #9d9d9d77;

				&:hover {
					background: #9d9d9d;
				}
			}

			.crm-item {
				display: flex;
				align-items: center;
				gap: 8px;

				.item-icon {
					width: 36px;
					height: 36px;
					border-radius: 8px;
					background-color: #f8f8fc;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 1.2em;
				}

				.item-details {
					flex: 1;
					font-size: 1.2em;
					font-weight: 500;
				}

				.item-days {
					margin-right: 8px;
				}

			}
		}
	}

	.ana-right-wrapper {
		overflow-y: scroll;
		overflow-x: hidden;

		&::-webkit-scrollbar {
			display: none;
		}

		.ana-body-wrapper {
			padding: 48px 32px;
			font-size: 1em;
			display: grid;
			gap: 24px;
			grid-template-columns: repeat(6, 1fr);
			// background-color: #f1f1fc;
			// grid-auto-rows: auto;

			.section-title {
				display: flex;
				align-items: center;
				justify-content: space-between;

				h2 {
					font-size: 2em;
					color: #272727;
					font-weight: 800;
				}
			}

			// highlight block
			.ana-highlight-block {
				// background: linear-gradient(140deg, #ff8774 0%, #693a41 55%, #1c1222 100%);
				// background: linear-gradient(140deg, rgba(33, 122, 181, 1) 0%, rgba(28, 18, 34, 1) 100%);
				box-shadow: 2px 2px 10px 0px #9d9d9d;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 16px;
				padding: 24px 16px;
				border-radius: 16px;

				.highlight-icon-container {
					width: 48px;
					height: 48px;
					border-radius: 5px;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #e7e7e777;
					color: #f8f8fc;
					font-size: 1.8em;
				}

				.highlight-details {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 4px;

					.highlight-main-value {
						font-size: 2.5em;
						font-weight: 800;
						color: #f8f8fc;

						span {
							font-size: 0.7em;
						}
					}

					.highlight-main-label {
						font-size: 1.2em;
						color: #d8d8d8;
					}

					.highlight-change-data {
						font-size: 1em;
						font-weight: 800;
						display: flex;
						align-items: center;
						justify-content: center;
						gap: 4px;
						color: #f8f8fc;

						i {
							margin-bottom: 2px;
						}
					}
				}

				.highlight-breakdowns {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-wrap: wrap;
					gap: 16px;
					margin-top: 16px;

					.breakdown-container {
						padding: 8px 16px;
						border-radius: 5px;
						background-color: #e7e7e7;
						display: flex;
						flex-direction: column;
						align-items: center;

						.breakdown-value {
							font-size: 1.2em;
							font-weight: 800;
							color: #272727;

							span {
								font-size: 0.8em;
							}
						}

						.breakdown-label {
							font-size: 1em;
							color: #9d9d9d;
						}
					}
				}
			}

			.ana-flex-wrapper {
				display: flex;
				flex-wrap: wrap;
				gap: 24px;

				.ana-flex-group {
					flex: 1 0 200px;
					display: flex;
					flex-wrap: wrap;
					gap: 24px;
				}
			}

			.ana-summary-block {
				display: flex;
				flex-direction: column;
				gap: 24px;
				padding: 24px;
				border-radius: 16px;

				.summary-block-header {
					color: gray;
					margin-bottom: 12px;
					font-size: 1.5em;
				}

				.summary-breakdown-wrapper {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-wrap: wrap;
					gap: 24px;

					.summary-breakdown-container {
						flex: 1;
						display: flex;
						flex-direction: column;
						gap: 24px;

						.s-breakdown-chart {
							display: flex;
							flex-direction: column;
							gap: 8px;
							min-width: 220px;
						}

						.s-breakdown-main {
							text-align: center;
							font-size: 1.8em;
							font-weight: 800;
							color: #272727;

							span {
								font-size: 0.7em;
							}
						}

						.s-breakdown-details {
							align-self: stretch;
							flex: 1;
							display: flex;
							flex-direction: column;
							gap: 8px;

							.data-sub-container {
								display: flex;
								align-items: center;
								flex: 1;
								gap: 16px;

								.label-indicator {
									width: 20px;
									height: 10px;
								}

								.data-col {
									display: flex;
									flex-direction: column;
									gap: 4px;

								}

								.data-sub-label {
									font-size: 1.2em;
									color: #9d9d9d;
								}

								.data-sub-data {
									font-size: 1.22em;
									font-weight: 800;
									color: #272727;

									span {
										font-size: 12px;
									}

								}
							}
						}
					}
				}

			}

			.ana-vat-block {
				display: flex;
				flex-direction: column;
				gap: 24px;
				padding: 24px;
				border-radius: 16px;

				.vat-graph-container {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 16px;

					.vat-main-container {
						display: flex;
						flex-direction: column;
						gap: 4px;
						align-items: center;

						.vat-main-data {
							font-size: 2em;
							font-weight: 800;
							color: #272727;

							span {
								font-size: 0.7em;
							}
						}

						.vat-main-label {
							font-size: 1.2em;
							color: #9d9d9d;
						}
					}
				}

				.vat-breakdowns-wrapper {
					display: flex;
					flex-direction: column;
					gap: 16px;

					.vat-breakdown-container {
						display: flex;
						gap: 16px;
						align-items: center;

						.label-indicator {
							width: 20px;
							min-width: 20px;
							height: 10px;
							margin-bottom: 2px;
						}

						.wrap-row {
							flex: 1;
							display: flex;
							flex-wrap: wrap;
							align-items: center;
							justify-content: space-between;
							gap: 8px;

							&.noWrap {
								flex-wrap: nowrap;
							}

							.breakdown-label {
								font-size: 1.1em;
								font-weight: 600;
								color: #272727;

								&.light {
									color: #9d9d9d;
								}
							}

							.breakdown-label-container {
								flex: 1 0 120px;
								display: flex;
								flex-direction: column;
								gap: 4px;

								.breakdown-description {
									font-size: 1em;
									color: #9d9d9d;
								}
							}

							.breakdown-data {
								font-size: 1.32em;
								font-weight: 800;
								color: #272727;

								span {
									font-size: 0.7em;
								}
							}
						}

					}
				}
			}

			.flex-block {
				grid-column: span 4;
			}

			.highlight-block {
				grid-column: span 2;
			}

			.ana-block {
				grid-column: span 2;
			}

			.mini-block {
				grid-column: span 1;
			}

			.full-block {
				grid-column: span 6;
			}

			.half-block {
				grid-column: span 3;
			}

			.quarter-block {
				grid-column: span 4;
			}

			.compare-block {
				grid-column: span 6;
			}

		}
	}

	// .ana-list-wrapper {
	// 	position: absolute;
	// 	display: flex;
	// 	right: -100%;
	// 	top: -25px;
	// 	width: 100%;
	// 	height: 100%;

	// 	&.expanded {
	// 		right: 0;

	// 		.ana-overlay {
	// 			opacity: 1;
	// 		}

	// 		.content {
	// 			right: 0
	// 		}
	// 	}

	// 	.ana-overlay {
	// 		background-color: #27272722;
	// 		width: 100%;
	// 		height: 100%;
	// 		opacity: 0;
	// 		transition: opacity 0.3s ease-in-out;
	// 	}

	// 	.content {
	// 		position: absolute;
	// 		right: -100%;
	// 		top: 0;
	// 		display: flex;
	// 		width: 50%;
	// 		height: 100%;
	// 		background-color: white;
	// 		transition: right 0.5s ease-in-out;
	// 	}
	// }
}

@media screen and (max-width: 1440px) {
	.ana-wrapper {
		font-size: 10px;
	}
}


@media screen and (max-width: 1224px) {
	.ana-wrapper {
		grid-template-columns: 1fr;
		gap: 24px;
		font-size: 9px;
		padding: 48px 8px;
		overflow-y: scroll;
		overflow-x: hidden;

		.ana-right-wrapper {
			overflow: clip;

			.ana-body-wrapper {
				padding: 0 24px;
				grid-template-columns: repeat(4, 1fr);


				.ana-block {
					grid-column: span 2;
				}

				.flex-block {
					grid-column: span 2;
				}

				.mini-block {
					grid-column: span 1;
				}

				.full-block {
					grid-column: span 4;
				}

				.half-block {
					grid-column: span 2;
				}

				.quarter-block {
					grid-column: span 4;
				}

				.compare-block {
					grid-column: span 2;
				}

			}
		}

		.ana-left-wrapper {
			overflow: clip;
			background-color: transparent;
			padding: 0 24px;

			.ana-crm-container {
				display: none;
			}
		}

	}
}

@media screen and (max-width: 940px) {
	.ana-wrapper .ana-right-wrapper .ana-body-wrapper {

		.highlight-block {
			grid-column: span 4;
		}

		.flex-block {
			grid-column: span 4;
		}

	}
}

@media screen and (max-width: 768px) {
	.ana-wrapper .ana-right-wrapper .ana-body-wrapper {

		.ana-block {
			grid-column: span 4;
		}

		.mini-block {
			grid-column: span 2;
		}

		.full-block {
			grid-column: span 4;
		}

		.half-block {
			grid-column: span 4;
		}

		.quarter-block {
			grid-column: span 4;
		}

		.flex-block {
			grid-column: span 4;
		}

		.compare-block {
			grid-column: span 4;
		}

	}
}