@import '../../../assets/css/color-variable.scss';

.booking-summary {
	display: flex;
	flex-direction: column;
	width: 300px;
	height: 200px;
	position: relative;
	border-radius: 5px;

	.booked-time {
		display: flex;
		margin-right: 10px;
		box-shadow: -2px -2px 4px #FFF, 2px 2px 4px rgba($color: #1c1222, $alpha: 0.2);
		justify-content: center;
		align-items: center;
		width: 50px;
		position: absolute;
		top: 80px;
		left: 15px;


		span {
			font-size: 35px;
			color: #f8f8fc;
		}

		&.morning {
			background-color: var(--morning);
		}

		&.evening {
			background-color: var(--evening);
		}

		&.afternoon {
			background-color: var(--afternoon);
		}
	}



	.booked-date {
		width: 135px;
		height: 50px;
		// background-color: green;
		position: absolute;
		top: 80px;
		left: 80px;
		display: flex;
		align-items: center;

	}

	.booked-venue {
		position: absolute;
		top: 145px;
		left: 15px;
		right: 15px;
		display: flex;
		align-items: center;

		.venue-color-container {
			height: 40px;
			width: 40px;
			margin: 5px 10px 5px 0px;

			box-shadow: inset 2px 2px 4px rgba($color: #1c1222, $alpha: 0.2),
				inset -2px -2px 4px #fff;
			display: flex;
			justify-content: center;
			align-items: center;

			.venue-color-content {
				height: 30px;
				width: 30px;
				background-color: #f3cf3f;
			}
		}
	}


	.balance {
		width: 200px;
		height: 50px;
		display: flex;
		flex-direction: column;
		padding-top: 5px;
		// background-color: #f3cf3f;
		align-items: center;
		position: absolute;
		left: 15px;
		top: 15px;
	}

	.status {
		width: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		// background-color: firebrick;
		position: absolute;
		right: 15px;
		top: 80px;
		height: 50px;

	}

	.title-span {
		font-size: 12px;
		color: #9d9d9d;
	}

	.des-label {
		font-size: 14px;
		color: #474747;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		min-width: 44px;
		// background-color: red;

		&.amount {
			max-width: 130px;
			min-width: 10px;
		}
	}

	.inquiry {
		position: absolute;
		top: 15px;
		right: 15px;
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		// background-color: aquamarine;

		&.select {
			width: 50px;
			cursor: pointer;
		}
	}


	.booking-summary-status-wrapper {
		width: 60px;
		height: 30px;
		box-shadow: inset 2px 2px 4px rgba($color: #1c1222, $alpha: 0.2),
			inset -2px -2px 4px #fff;
		border-radius: 5px;

		.booking-summary-status {
			width: 50px;
			height: 20px;
			// background: #ff8774;
			background-color: var(--bg);
			border-radius: 5px;
			margin: 5px;
			font-size: 12px;
			color: #f8f8ff;
			display: flex;
			justify-content: center;
		}
	}
}

/* payment select check */


.payment-check-container {
	width: 30px;
	height: 30px;
	border-radius: 5px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;

}

.payment-balance-select-container {
	height: 20px;
	width: 20px;
}

.payment-balance-label {
	width: 20px;
	height: 20px;
	border-radius: 5px;
	cursor: pointer;

	&.disabled {
		width: 20px;
		height: 20px;
		border-radius: 5px;
		background-color: var(--text-midgray);
		cursor: not-allowed;
	}

}

.payment-balance-checkbox[type=checkbox] {
	display: none;
}

.payment-balance-checkbox:checked+.payment-balance-label {
	background-color: var(--highlight-green);

}