.openshop {
	// border: 1px solid red;
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: center;
	padding: 20px 10px;

	i {
		color: black;
		font-size: 80px;
	}

	&.new-pin {
		padding: 5px;

		>p {
			font-size: 12px;
			line-height: 12px;
			margin-bottom: 0px;
		}
	}

}

.inputbox {
	max-height: 80px;
	max-width: 50px;
	min-width: 40px;
	min-height: 40px;
	font-size: 24px;
	font-weight: bold;
	// border: 1px solid red;
	border-radius: 10px;
	background-image: linear-gradient(107deg, #F7F7FA, #F4F4F8);
	box-shadow: inset 1px 2px 4px rgba(28, 18, 34, 0.2),
		inset -2px -2px 4px rgb(255, 255, 255);

	text-align: center;
	caret-color: transparent;

	&::selection {
		color: red;
		background: transparent;
	}

	&.small {
		width: 40px;
		height: 40px;
	}
}

.open-shop-warn {
	display: flex;
	// width: 180px;
	align-items: center;
	justify-content: center;
	gap: 10px;
	padding: 5px;


	>div {
		display: flex;
		gap: 5px;
		justify-content: center;
		flex-wrap: wrap;

		p {
			font-size: 9px;
		}

		i {
			font-size: 9px;
		}

		.red {
			color: red;
		}

		.green {
			color: green;
		}
	}

}

.open-shop-buttons {
	display: flex;
	padding-top: 10px;
	// border: 1px solid red;
	align-items: center;
	justify-content: center;
	gap: 10px;
}