.main-modal-wrapper {
	width: 600px;
	min-height: 190px;

	&.switch-user {
		min-height: 190px;
	}

	&.deactivate-company {
		min-height: 230px;
	}

	&.payment-received {
		width: 500px;
		height: 453px;
	}

}

.modal-title {
	color: var(--text-dark-grey);
	text-align: center;
	font-size: 18px;
	// background-color: red;
	padding-top: 20px;

	&.delete {
		color: var(--highlight-red);
	}

}

.modal-sub-title {
	color: var(--text-midgray);
	text-align: center;
	font-size: 12px;
	padding: 0px 30px;
	margin: 5px 0px;

}

.modal-message {
	color: var(--text-midgray);
	text-align: center;
	font-size: 12px;
	margin-top: 20px;
	padding: 0px 30px;
	margin-bottom: 10px;

	&.delete {
		color: var(--highlight-red);
	}



}

.modal-three-button-wrapper {
	flex-direction: row;
	justify-content: space-between;
	display: flex;
	margin: 30px 0px;
	// background-color: red;
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 0px 20px;

	& div button:first-child {
		margin-right: 10px;
	}
}

.modal-two-button-wrapper {
	flex-direction: row;
	justify-content: center;
	display: flex;
	margin: 25px 0px;
	// position: absolute;
	bottom: 0;
	width: 100%;
	gap: 20px;

	&.right {
		justify-content: flex-end;
	}
}


@media only screen and (max-width: 600px) {
	.main-modal-wrapper {
		width: 315px;
	}

}

// styles for edit-payment.modal.jsx component for date
.m-payment-field-wrapper {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 5px;
	padding-right: 16px;

	label {
		font-size: 12px;
	}

	.payment-date-input {
		display: flex;
		align-items: flex-start;
		gap: 16px;
		height: 56px;

		.date-inputs {
			flex: 1;
			width: 100%;

			.fullWidth {
				width: 100%;
			}
		}

		.payment-date-switch {
			font-size: 12px;
			width: 32px;
			height: 32px;
			margin-top: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			border: 2px solid var(--afternoon);
			border-radius: 2px;

			&:hover {
				color: white;
				background: var(--afternoon);
				cursor: pointer;
			}
		}
	}
}