.vat-calculate-button {
	display: flex;
	height: 30px;
	align-items: center;
	justify-content: center;
	margin: 0px 10px;
	border-radius: 5px;

}

.option-display {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-height: 250px;
	padding: 10px;
	overflow-y: auto;
	overflow-x: hidden;
	border: 1px solid red;

	.option-title-container {
		display: flex;
		flex-direction: column;
		width: 100%;

		.option-title {
			display: flex;
			height: 30px;
			align-items: center;
			justify-content: center;
			margin-bottom: 5px;
			background-color: var(--primary-color);
			border-radius: 5px;
			gap: 10px;

			>div {
				display: flex;
				font-size: 14px;
				// font-weight: bold;
				color: white;
			}
		}

		.option-items-container {
			display: flex;
			width: 100%;
			padding: 10px;
			gap: 10px;
			flex-wrap: wrap;

			.option-items {
				display: flex;
				flex-direction: column;
				padding: 5px;
				max-width: 180px;
				min-width: 100px;
				border-radius: 5px;

				>div {
					font-size: 12px;
					text-align: right;
				}

				&.selected {
					border: 2px solid var(--afternoon);
					box-sizing: border-box;
				}
			}
		}
	}

	.option-message-title {
		display: flex;
		height: 30px;
		justify-content: center;
		align-items: center;
		font-size: 16px;
		font-weight: bold;
		color: white;
		margin-bottom: 5px;
		background-color: var(--primary-color);
		border-radius: 5px;
	}

	.option-message {
		padding: 10px;
		font-size: 12px;
	}
}