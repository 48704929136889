// date input

.en-np-input-wrapper {
	width: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;

	.en-np-input-label {
		font-size: var(--h6);
		color: var(--text-dark-grey);
		margin-bottom: 8px;
		white-space: nowrap;
	}

	.validation-error {
		color: var(--highlight-red);
		font-size: var(--h7);
		text-align: right;
		height: 15px;
	}
}

.en-np-input-date-wrapper {
	width: 100%;
	display: flex;
	gap: 8px;

	.date-input {
		flex: 1;
		padding: 0 10px;
		height: 32px;
		background-image: linear-gradient(107deg, #f7f7fa, #f4f4f8);
		box-shadow: inset 1px 2px 4px rgba(28, 18, 34, 0.1),
			inset -2px -2px 4px rgb(255, 255, 255);
		border-radius: 5px;
		border: 0;
		outline: 0;
		color: var(--primary-color);
		font-size: var(--h5);
	}

	.date-type-options {
		min-width: 64px;
		display: flex;
		align-items: center;
		border: 1px solid #d8d8d866;
		border-radius: 4px;
		overflow: hidden;

		.type-btn {
			height: 100%;
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 0.8em;
			color: #272727;
			cursor: pointer;
			transition: all 200ms ease-in-out;

			&:hover {
				background-color: #f1f1fc;

				&.en {
					background-color: #1476b7;
				}

				&.np {
					background-color: #9c3222;
				}
			}

			&.en {
				color: #f8f8fc;
				background-color: #3498db;
			}

			&.np {
				color: #f8f8fc;
				background-color: #d35a47;
			}

		}
	}
}