.add-image-modal-wrapper {
    width: 600px;
    padding: 30px;
    display: flex;

    .add-image-wrapper {
        width: 300px;
        height: 250px;

        .add-image-container {
            width: 300px;
            height: 250px;
            margin-top: -50px !important;
            margin-left: 25px;
            border-radius: 10px;

        }
    }

    .description-wrapper {
        width: 50%;
        // background-color: green;
        height: 250px;
        position: relative;

        .description-title {
            text-align: center;
            color: var(--text-dark-grey);
            font-size: 20px;
        }

        .description-message {
            text-align: center;
            font-size: 14px;
            text-justify: auto;
            margin-top: 20px;
            color: var(--text-midgray);
        }

        .description-button-container {
            width: 100%;
            position: absolute;
            bottom: 0;
            display: flex;
            justify-content: space-between;
            padding: 0px 10px;
            box-sizing: border-box;

            .add-image-cancel-button,
            .add-image-save-button {
                width: 100px;
                height: 24px;
                border-radius: 40px;
            }

            .add-image-save-button {
                background-color: var(--highlight-green);
                color: var(--background-color);
            }
        }
    }

}

@media only screen and (max-width: 750px) {
    .add-image-modal-wrapper {
        width: 320px;
        height: 450px;
        padding: 10px;
        display: flex;
        flex-direction: column;

        .add-image-wrapper {
            margin-top: 10px;

            .add-image-container {
                width: 250px;
            }
        }

        .description-wrapper {
            width: 100%;
            margin: 10px 0px;
        }
    }
}