.item-container {
	width: 135px;
	height: 119px;
	cursor: pointer;
	position: relative;
	border-radius: 5px;

	&.saved {
		pointer-events: none;
	}

	.item-icon {
		position: absolute;
		width: 30px;
		height: 30px;
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 10px;
		left: 10px;


		&.veg {
			padding: 7px;
		}

		&.selected,
		&.saved {
			background: #F09E9B 0% 0% no-repeat padding-box;

			&.veg {
				background: #A9CE7B 0% 0% no-repeat padding-box;
			}
		}

	}

	.item-name {
		position: absolute;
		height: 40px;
		bottom: 10px;
		left: 10px;
		right: 10px;
		color: #474747;
		font-size: 14px;
		text-align: end;
		overflow: hidden;
	}

	.item-cartilage {
		position: absolute;
		top: 20px;
		left: 55px;
		margin: auto;
		font-size: 12px;
		width: 17px;
		color: var(--text-midgray);
	}

	.item-price {
		position: absolute;
		top: 10px;
		right: 10px;
		margin: auto;
		font-size: 12px;
		width: 60px;
		text-align: right;
		color: var(--text-midgray);
	}

	.item-type {
		position: absolute;
		bottom: 55px;
		right: 10px;
		margin: auto;
		font-size: 12px;
		width: 60px;
		color: var(--text-midgray);
		text-align: end;
	}
}