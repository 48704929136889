.drink-item-wrapper {
	display: flex;
	flex-direction: column;
	width: 285px;
	min-height: 50px;
	padding: 10px;
	border-radius: 5px;

	.drink-info-container {
		display: grid;
		height: 45px;
		grid-template-columns: 30px 1fr;
		margin-bottom: 5px;

		.drink-icon {
			width: 30px;
			height: 30px;
			border-radius: 10px;
			display: flex;
			justify-content: center;
			align-items: center;

			&.selected,
			&.saved {
				background: var(--afternoon) 0% 0% no-repeat padding-box;
				i {
					color: var(--background-color) !important;
				}
			}
		}
		.drink-info {
			display: flex;
			flex-direction: column;

			& > div {
				display: flex;
				justify-content: right;
			}

			.drink-b-price {
				color: var(--text-midgray);
				padding-right: 2px;
			}
		}
	}

	.drink-details-container {
		.drink-fields {
			display: flex;
			margin-top: 5px;
		}

		.drink-action-wrapper {
			display: grid;
			column-gap: 5px;
			grid-template-columns: 1fr 1fr;

			& > div {
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 5px;
				cursor: pointer;
			}

			.save-drink {
				background: var(--highlight-green);
				color: white;
			}

			.edit-drink {
				&:hover {
					background: var(--highlight-green);
					color: white;
				}
			}

			.delete-drink {
				&:hover {
					background: var(--highlight-red);
					color: white;
				}
			}
		}
	}
}
