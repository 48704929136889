$text-grey: rgb(94, 94, 94);


.ana-revenue {
	display: flex;
	position: relative;
	width: 100%;
	padding: 5px 10px;
	border-radius: 5px;
	margin-bottom: 10px;
	border: 1px solid transparent;
	// background-color: green;

	.ana-nav-chart {
		display: flex;
		width: 60px;
		position: relative;

		// border: 1px solid red;
		.ana-chart-icon-float {
			display: flex;
			width: 100%;
			height: 100%;
			align-items: center;
			justify-content: center;
			position: absolute;
			border-radius: 50%;

			// background-color: red;
			>i {
				color: grey;

				&:hover {
					color: black;
				}
			}
		}
	}

	.ana-nav-text {
		display: flex;
		flex: 1;
		flex-direction: column;

		>div {
			text-align: left;
		}

		.nav-text-label {
			font-size: 16px;
			font-weight: bold;

		}

		.nav-text-data {
			font-size: 12px;

			&.light {
				color: grey;
				// background-color: rgba(63, 63, 63, 0.8);
			}
		}

	}

	&:hover {
		cursor: pointer;
		background-color: rgba($color: #523563, $alpha: 0.5);

		>div {
			color: white;
		}

		// border: 1px solid red;
	}
}

.ana-line-container {
	display: flex;
	flex-direction: column;
	gap: 16px;
	height: 100%;

	.ana-line-header {
		display: flex;
		flex-direction: column;
		gap: 8px;
		margin-bottom: 12px;

		.ana-l-text {
			font-size: 1.5em;
			color: gray;
			margin: 0;
		}

		.ana-inputs-container {
			display: flex;
			gap: 16px;
			align-items: flex-end;
			justify-content: space-between;
		}

		.ana-l-field {
			font-size: 1.1em;

			&>label {
				font-size: 1em;
				color: #9d9d9d;
				margin-right: 5px;
			}

			&>input {
				text-align: right;
				width: 100px;
				border: 1px solid #d8d8d866;
				border-radius: 4px;
				padding: 0 8px;
				background-color: #f1f1fc;
				height: 28px;
			}

			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 2px;
				appearance: none;
			}

			input[type="number"] {
				-moz-appearance: textfield;
				appearance: textfield;
			}
		}
	}

	& p {
		font-size: 1.2em;
		color: grey;
		margin-bottom: 0;
	}

	.ana-line-chart {
		display: flex;
		min-width: 100%;
		min-height: 220px;
		margin-bottom: 5px;
		transition: all 0.5s ease-in-out;
		// border: 1px solid red;

		&.line-full {
			// min-width: 2800px;
			position: relative;
		}
	}

	.ana-chart-scroller {
		display: flex;
		overflow-x: scroll;
		direction: rtl;

		&::-webkit-scrollbar {
			// display: none;
			height: 2px;
		}
	}

	.ana-line-title {
		display: flex;
		width: 100%;
		justify-content: space-between;

		.ana-legend-row {
			display: flex;
			gap: 16px;

			&.col {
				flex-direction: column;
				gap: 8px;
			}

			.ana-legend {
				display: flex;
				align-items: center;
				gap: 8px;

				p {
					font-size: 1.1em;
					line-height: 10px;
				}

				.ana-legend-color {
					width: 9px;
					height: 9px;
					border-radius: 2px;
					margin-bottom: 2px;
				}
			}
		}

	}

	.ana-scatter-chart {
		flex: 1;
		position: relative;
		display: flex;
		margin-bottom: 5px;
	}
}


.ana-dg-component {
	display: flex;
	flex-direction: column;
	padding: 5px;
	border-radius: 5px;

	.dg-headder {
		display: flex;
		// gap: 5px;
		font-size: 1.5em;
		margin-bottom: 12px;

		.dg-headder-title {
			flex: 1;
		}

		.dg-headder-button {
			display: flex;
			width: 20px;
			justify-content: center;
			align-items: center;
			border: 2px solid var(--highlight-grey);
			border-radius: 2px;
			box-sizing: border-box;
			font-size: 10px;
			color: var(--highlight-grey);

			&:hover {
				cursor: pointer;
				color: white;
				background-color: var(--afternoon);
				border-color: var(--afternoon);

			}
		}
	}


	& p {
		display: flex;
		margin-bottom: 0;
		font-size: 1.2em;
		color: $text-grey;
	}

	.dg-main-data {
		display: flex;
		flex: 1;
		justify-content: space-between;
		align-items: center;

		.dg-main-data-container {

			.dg-data {
				display: flex;
				gap: 10px;

				.dg-data {
					font-size: 2em;
					font-weight: bold;

				}

				.dg-percent {
					display: flex;
					font-size: 1.4em;
					align-items: center;

				}
			}
		}

		.dg-main-icon-container {
			display: flex;
			height: 40px;
			width: 40px;
			border-radius: 5px;
			background-color: #523563;
			justify-content: center;
			align-items: center;

			&>i {
				font-size: 2em;
				color: white;
			}
		}
	}

	.dg-sub-data-wrapper {
		display: flex;
		flex: 1;
		flex-wrap: wrap;
		margin: 10px 0;
		padding-top: 5px;

		.dg-sub-container {
			display: flex;
			flex: 1;
			min-width: 100px;
			height: fit-content;
			flex-direction: column;
			margin-bottom: 5px;

			.dg-sub-label {
				font-size: 1.2em;
				color: $text-grey;
			}

			.dg-sub-data {
				font-size: 1.6em;
				font-weight: bold;
			}
		}
	}

}


.ana-dg-dif-container {
	height: 100%;

	.ana-dg-dif-component {
		display: flex;
		flex-direction: column;
		gap: 16px;
		height: 100%;

		.dg-dif-header {
			margin-bottom: 12px;
			font-size: 1.5em;
			color: gray;
		}

		.ana-dg-dif-chart {
			flex: 1;
			position: relative;
			min-height: 220px;
		}
	}
}

.ana-dl-wrapper {

	.dl-list-item-container {
		display: grid;
		grid-template-columns: 30px 1fr 1fr 1fr 1fr 60px 50px 50px;
		margin-bottom: 5px;

		// border: 1px solid red;
		// justify-content: space-between;
		&.title {
			// background-color: #523563;
			border-bottom: 1px solid #523563;

			>div {
				padding: 2px 5px;
				// color: white;
				font-size: 1em;
				font-weight: bold;
			}
		}

		>div {
			font-size: 1.2em;
			padding: 0px 5px;
			// border: 1px solid red;

		}

		&:hover {
			background-color: rgba($color: #523563, $alpha: 0.2);
			// opacity: 0.2;
		}


	}
}

.ana-polar-wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;

	.ana-polar-header {
		color: gray;
		margin-bottom: 12px;
		font-size: 1.5em;
	}


	.ana-polar-container {
		display: flex;
		flex: 1;
		gap: 40px;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		.ana-polar-chart {
			width: 200px;
		}

		.polar-data-list {
			display: flex;
			flex-direction: column;
			flex: 1;

			.pd-list-block {
				display: grid;
				grid-template-columns: 132px 1fr 40px;
				padding: 0px 5px;
				gap: 5px;

				& div {
					font-size: 1.2em;
					align-items: center;
				}

				.pd-l-label {
					display: flex;

					.pd-l-icon {
						height: 12px;
						width: 12px;
						min-width: 12px;
						border-radius: 2px;
						margin-right: 8px;
					}

					.pd-l-text {
						font-size: 1.1em;
						color: #272727;
					}
				}

				.pd-l-data-bar {
					min-width: 40px;
					display: flex;
					align-items: center;

					&>div {
						height: 6px;
						border-radius: 2px;
					}
				}

				.pd-l-data {
					display: flex;
					font-weight: bold;
					justify-content: right;

				}

				&:hover {
					background-color: lightcyan;

				}
			}
		}
	}


}