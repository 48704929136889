.checkbox-container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 14px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container .checkbox-input {
	position: absolute;
	top: 0;
	left: 0;
	height: 15px;
	width: 15px;
	border: none;
	-webkit-appearance: none;
	outline: none;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 15px;
	width: 15px;
	margin: 3px 3px 3px 4px;
	box-shadow: inset -1px -2px 4px #FFF, inset 1px 2px 4px rgba(28, 18, 34, 0.2);
}

/* span.signin{
	background-color: #FFF;
} */

.checkbox-container .checkbox-input:checked {
	box-shadow: -1px -2px 4px #FFF, 1px 2px 4px rgba(28, 18, 34, 0.2);
}

/* .checkbox-container .signin:checked{
	box-shadow: none;
} */

/* Show the checkmark when checked */
.checkbox-container .checkbox-input:checked~.checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
	content: "";
	position: absolute;
	display: none;
	left: 5px;
	top: 2px;
	width: 3px;
	height: 6px;
	border: solid #52BE7F;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}