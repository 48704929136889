.booking-smv-form-container {
	display: flex;
	flex-direction: column;
	gap: 16px;

	.bsmv-form-row {
		flex: 1;
		display: flex;
		flex-wrap: wrap;
		gap: 16px;

		.bsmv-form-input {
			width: 100%;
			flex: 1;
			min-width: 100px;
		}

		.bsmv-form-textarea {
			max-height: 120px;
		}

		.bsmv-form-input-field {
			min-height: 32px;
			max-height: 32px;
		}
	}

	.bsmv-form-buttons {
		padding: 16px 0;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 16px;

		button[disabled] {
			cursor: none;
			pointer-events: none;
			box-shadow: none;
			opacity: 0.5;
		}

		.bsmv-button {
			border: 0;
			outline: 0;
			font-size: 1.1em;
			white-space: nowrap;
			color: var(--background-color);
			background-color: var(--afternoon);
			padding: 8px 16px;
			cursor: pointer;
			display: flex;
			align-items: flex-end;
			justify-content: center;
			gap: 8px;
			box-shadow: -1px 1px 4px rgba(22, 49, 30, 0.2), 1px -1px 4px #fcfcff;
			border-radius: 4px;
			transition: all 200ms ease-in-out;

			i {
				margin-top: -4px;
			}

			&:hover {
				box-shadow: none;
				transform: scale(0.95);
			}
		}
	}

}

// section header
.section-header {
	align-self: start;
	font-size: 1.34em;
	color: var(--text-dark-grey);
	font-weight: 600;
	text-transform: uppercase;
	position: relative;
	margin: 8px 0;

	&::before {
		content: "";
		width: 50%;
		height: 4px;
		border-radius: 4px;
		background-color: var(--text-light-grey);
		position: absolute;
		bottom: -4px;
		left: 0;
	}
}

// booking payments list view style
.payment-list-wrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;

	.payment-list-container {
		display: flex;
		flex-wrap: wrap;

		.payment-item-container {
			flex: 1;
			min-width: 25%;
			max-width: 25%;
			padding: 8px;

			@media (max-width: 1440px) {
				$width: 100% / 3; // Calculate the width as a percentage
				min-width: $width;
				max-width: $width;
			}

			@media (max-width: 1200px) {
				min-width: 50%;
				max-width: 50%;
			}

			@media (max-width: 768px) {
				min-width: 100%;
				max-width: 100%;
			}

			.payment-list-item {
				padding: 16px;
				display: flex;
				flex-direction: column;
				gap: 8px;

				@media (max-width: 1080px) {
					padding: 8px;
				}

				.mainText {
					font-size: 1.2em;
					font-weight: 500;
					color: var(--text-dark-grey);
					text-transform: uppercase;
				}

				.subText {
					font-size: 1.1em;
					font-weight: 500;
					color: var(--text-midgray);
				}

				.highlightText {
					font-size: 1em;
					font-weight: 600;
					color: var(--text-dark-grey);
					text-transform: uppercase;
					text-align: right;

					b {
						font-size: 1.32em;
					}
				}
			}
		}

	}

}

// secondary dashed button style
.check-button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px;
	gap: 8px;
	text-transform: uppercase;
	font-size: 1.2em;
	cursor: pointer;
	border-radius: 4px;
	border: 1px dashed var(--text-light-grey);
	color: var(--text-midgray);
	transition: all 300ms ease-in-out;

	&[disabled] {
		cursor: none;
		pointer-events: none;
		box-shadow: none;
		opacity: 0.5;
	}

	b {
		font-weight: 500;
	}

	i {
		margin-top: -2px;
	}

	&:hover {
		color: var(--afternoon);
		border-color: var(--afternoon);
	}
}

// booking payment container
.booking-payment-details-container {
	display: flex;
	flex-direction: column;
	gap: 16px;

	.payment-details {
		display: flex;
		flex-wrap: wrap;
		gap: 16px;

		@media (max-width: 1024px) {
			flex-direction: column;
			gap: 8px;
		}

		.payment-detail-block {
			flex: 1;
			white-space: nowrap;

			@media (max-width: 1024px) {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.detail-label {
				font-weight: 500;
				font-size: 1.1em;
				color: var(--text-midgray);
			}

			.detail-value {
				font-weight: 500;
				font-size: 1em;
				color: var(--text-dark-grey);
				text-transform: capitalize;

				i {
					margin-right: 4px;
				}

				b {
					font-size: 1.24em;
				}

			}
		}
	}
}

// booking add payment styles
.payment-options-container {
	flex: 1;
	max-width: 50%;
	display: flex;
	flex-direction: column;
	margin-bottom: 1px;

	.options-label {
		font-size: var(--h6);
		color: var(--text-dark-grey);
		margin-bottom: 8px;
	}

	.option-block {
		display: flex;
		height: 30px;
		gap: 8px;
		margin-bottom: 10px;

		.bp-options {
			display: flex;
			flex: 1;
			gap: 10px;

			.bp-option-label {
				padding: 0 8px;
				display: flex;
				font-size: 12px;
				width: 100%;
				border-radius: 5px;
				align-items: center;
				justify-content: center;
				text-transform: capitalize;

				>i {
					margin-right: 10px;
				}

				&.errorlogger {
					border: 1px solid red;
					box-sizing: border-box;
				}
			}

			.bp-option-checkbox[type=radio] {
				display: none;
			}

			.bp-option-checkbox:checked+.bp-option-label {
				background: #3498DB;
				color: var(--background-color);

				i {
					color: var(--background-color);
				}
			}
		}

	}

	.validation-err {
		color: var(--highlight-red);
		font-size: var(--h7);
		text-align: right;
		height: 15px;
	}
}

// vat recorded message // payment list empty message
.details-message {
	padding: 16px;
	text-align: center;
	font-size: 1.2em;
	color: var(--text-midgray);
	font-weight: 500;
}