.switch-customer-modal-wrapper {
	width: 600px;
	height: 193px;
	display: flex;
	flex-direction: column;
	padding: 25px;
	box-sizing: border-box;
	align-items: center;

	h3 {
		font-size: 18px;
		height: 17px;
		color: var(--text-dark-grey);
	}

	p {
		font-size: 12px;
		text-align: center;
		margin-top: 20px;
		width: 420px;
		height: 42px;
		color: var(--text-midgray);
	}

	ul li {
		font-size: 12px;
		list-style-type: circle;
		color: var(--text-midgray);
	}

	.modal-action-button-container {
		display: flex;
		flex-direction: row;
		gap: 30px;
		margin-top: 15px;

		.button-container {
			height: 24px;
			width: 100px;
			font-size: 12px;
			border-radius: 40px;
		}
	}
}
