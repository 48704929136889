@import "../../assets/css/color-variable.scss";
@import "../../assets/css/size-variable.scss";

.venue-number-selection-container {
    flex: 1;
}

.price-indicator-label {
    font-size: var(--h6);
    color: var(--text-midgray);
    margin: 8px 0px 15px 0px;
}

.subscription-selection-container {
    flex: 1;
    border-radius: 5px;

    .subscription-cost-content-selected {
        grid-area: trial;
        background-color: var(--highlight-green);
        position: relative;
        border-radius: 5px;

    }

    .subscription-cost-content-monthly {
        grid-area: monthly;
        position: relative;
    }

    .subscription-cost-content-anually {
        grid-area: anually;
        position: relative;
    }

    .subscription-cost-container {
        display: grid;
        grid-template-areas:
            'trial monthly'
            'trial anually';
        grid-gap: 15px;
        height: 120px;
    }
}

.subscription-cost-title {
    position: absolute;
    font-size: var(--h6);
    color: var(--text-midgray);
    top: 10px;
    right: 10px;

    &.selected {
        color: var(--background-color);
        top: 20px;
        left: 10px;
    }
}

.subscription-cost-amount {
    font-size: var(--h5);
    position: absolute;
    top: 25px;
    right: 10px;

    &.selected {
        color: var(--background-color);
        font-size: var(--h4);
        top: 85px;
        right: 10px;
    }

}

.confirm-signup-button {
    background-color: var(--highlight-green);
    width: 100px;
    height: 30px;
    border-radius: 40px;
    color: var(--gradient-background-one);
    font-size: var(--h5);
}

.email-confirmation-title,
.email-confirmation-message {
    text-align: center;
}

.email-confirmation-title {
    padding-top: 150px;
    font-size: var(--h2);
    color: var(--text-dark-grey);
}

.email-confirmation-message {
    margin-top: 30px;
    padding: 0px 40px;
    font-size: var(--h5);
    color: var(--highlight-grey);
}