.bfix-wrapper {
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	overflow-x: auto;
	height: 100vh;
	width: 100vw;
	padding-bottom: 32px;

	.bfix-header {
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding: 32px;

		h2 {
			font-size: 24px;
			color: #272727;
			font-weight: 600;
			margin: 0;
		}

		p {
			font-size: 16px;
			color: #9d9d9d;
			margin: 0;
		}
	}

	.bfix-main {
		display: grid;
		grid-template-columns: 1fr 2fr;
		column-gap: 20px;
		max-height: 90vh;
		min-height: 90vh;
		overflow-y: hidden;
		padding: 32px;

		.bfix-left {
			display: flex;
			flex-direction: column;
			gap: 16px;
			min-width: 600px;
			overflow-y: scroll;
			overflow-x: hidden;

			&::-webkit-scrollbar-thumb {
				background-color: #9d9d9d77;

				&:hover {
					background: #9d9d9d;
				}
			}
		}

		.bfix-right {
			display: flex;
			min-width: 700px;
		}
	}
}


.bfix-booking-wrp {
	display: flex;
	flex-direction: column;
	padding-bottom: 16px;
	gap: 16px;
	border-bottom: 1px solid #e7e7e7;

	.bfix-booking-container {
		display: flex;
		gap: 16px;

		&.left-title {
			height: 40px;
			display: flex;
			align-items: center;
			background-color: #e7e7e7;
		}
	}

	.book-container {
		display: flex;
		padding-left: 10px;
		width: 200px;
		flex-direction: column;
		box-sizing: border-box;
		border-left: 4px solid var(--confirmed);

		&.bfix-close {
			border-left: 4px solid var(--pending);
			// background-color: #e7e7e7;
		}

		&[disabled] {
			pointer-events: none;
		}

		.bfix-details {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 8px;
			font-size: 14px;
			color: #272727;
			text-transform: capitalize;

			.bfix-elipsis {
				font-weight: bold;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.bfix-subText {
				font-size: 12px;
				color: #9d9d9d;
				text-transform: uppercase;

				&.morning {
					color: var(--morning);
				}

				&.afternoon {
					color: var(--afternoon);
				}

				&.evening {
					color: var(--evening);
				}
			}
		}


	}

	.bfix-dates {
		display: flex;
		flex: 1;
		gap: 10px;
		width: 330px;
		flex-wrap: wrap;
		align-items: flex-start;

		.bfix-current {
			display: flex;
			flex-direction: column;
			flex: 1;
			min-width: 90px;
			border-left: 4px solid transparent;

			&[disabled] {
				opacity: 0.5;
				pointer-events: none;
			}

			.bfix-en {
				display: flex;
				padding-left: 5px;
				min-width: 90px;
				color: #9d9d9d;

				&.lit {
					font-weight: bold;
					color: var(--afternoon);
				}
			}

			.bfix-np {
				display: flex;
				padding-left: 5px;
				min-width: 90px;
				color: #9d9d9d;

				&.lit {
					font-weight: bold;
					color: var(--pending);
				}
			}

			&.all-good {
				flex: 2;
				align-self: stretch;
				display: flex;
				font-size: 16px;
				text-transform: uppercase;
				color: #9d9d9d;
				justify-content: center;
				align-items: center;
				background-color: var(--hover-highlight);
				transition: all 200ms ease-in-out;
			}

			&.new:hover {
				cursor: pointer;
				background-color: var(--hover-highlight);
				border-color: var(--confirmed);
				border-radius: 0 50px 50px 0;
			}

			&.bfix-lit {
				box-sizing: border-box;
				background-color: var(--hover-highlight);
				border-color: var(--confirmed);
				border-radius: 0 50px 50px 0;
			}
		}

		.bfix-indicators {
			padding-left: 5px;
			width: 10px;
		}

		.bfix-manual-wrapper {
			display: flex;
			flex: 2;
			gap: 10px;
			align-items: center;
			min-width: 200px;

			.bfix-manual {
				display: flex;
				flex: 3;
				flex-direction: column;
				border-radius: 0 50px 50px 0;
				padding-left: 8px;
				background-color: var(--hover-highlight);
				border-left: 4px solid var(--confirmed);
			}

			.manual-indication {
				display: flex;
				flex: 1;
				align-items: center;
				justify-content: center;
				align-self: stretch;
				background-color: var(--afternoon);
				color: white;
				font-weight: bold;
				transition: all 200ms ease-in-out;

				&:hover {
					cursor: pointer;
					background-color: var(--pending);
					border-radius: 50px;
				}
			}
		}

		.manual-processing {
			flex: 2;
			align-self: stretch;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #f8f8fc;
			border-radius: 16px;
			background-color: var(--pending);
		}
	}
}

.bfix-buttons {
	display: flex;
	gap: 16px;
	align-items: center;
	justify-content: center;

	.bfix-date-changer {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 4px 16px;
		border-radius: 4px;
		background-color: #f8f8fc;
		box-shadow: 2px 2px 6px #00000030;
		font-size: 14px;
		color: #9d9d9d;

		&:hover {
			cursor: pointer;
			background-color: var(--afternoon);
			color: white;
		}

		&[disabled] {
			pointer-events: none;
			opacity: 0.5;
			color: grey;
			background-color: rgb(207, 207, 207);
			cursor: not-allowed;
		}
	}
}

.bfix-months {
	display: flex;
	flex: 1;
	flex-direction: column;

	.title {
		display: flex;
		height: 40px;
		align-items: center;
		justify-content: center;
		background-color: rgba(178, 178, 178, 0.664);
	}

	.day-title-container {
		grid-column: span 7;
		display: grid;
		grid-template-columns: repeat(7, 1fr);

		.day-title {
			display: flex;
			align-self: center;
			height: 40px;
			max-height: 40px;
			align-items: center;
			justify-content: center;
			font-weight: bold;
			background-color: #e7e7e790;
		}
	}

	.dates-container {
		display: grid;
		flex: 1;
		grid-template-columns: repeat(7, 1fr);

		.bfix-days {
			min-height: 100px;
			box-sizing: border-box;
			padding: 4px;
			background-color: #e7e7e790;
			border: 1px solid #fff;

			&.blanks {
				opacity: 0;
			}

			&.err-log {
				border-bottom: 4px solid #dc4545;
			}

			&.multi-log {
				border-bottom: 4px solid rgba(254, 149, 68, 0.393);
			}

			&.clickable-log {
				background-color: #fff;
				border: 1px solid #e7e7e7;
			}

			.bfix-date-text {
				font-size: 16px;
				color: #272727;
			}

			.booking-indicator {
				background-color: #e7e7e7;
				padding: 4px;
				margin: 4px;
				border-radius: 4px;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				column-gap: 8px;
				font-size: 12px;
				color: #f8f8fc;
				text-transform: capitalize;

				&.morning {
					background-color: var(--morning);
				}

				&.afternoon {
					background-color: var(--afternoon);
				}

				&.evening {
					background-color: var(--evening);
				}
			}
		}
	}
}

.bfix-ready-button {
	position: absolute;
	bottom: 40px;
	right: 40px;
	padding: 8px 24px;
	border-radius: 4px;
	border: 1px solid var(--afternoon);
	background-color: var(--afternoon);
	color: #f8f8fc;
	font-family: "sofia-pro";
	font-size: 16px;
	text-transform: uppercase;
	margin-bottom: 40px;
	cursor: pointer;
	box-shadow: 2px 2px 6px #00000030;
	transition: all 300ms ease-in-out;

	&:hover {
		transform: scale(1.1);
	}

	&[disabled] {
		pointer-events: none;
		background-color: var(--pending);
	}
}