.rq-item-wrapper {
    display: grid;
    height: 119px;
    width: 285px;
    grid-template-columns: 1fr 70px;
    padding: 10px;

    .rq-data-display-wrapper{
        display: flex;
        height: 100%;
        flex-direction: column;

        .rq-title{
            display: flex;
            position:relative;
            height: 30px;
            text-transform: capitalize;
            .rq-check{
                position:absolute;
                right: 0px;
                color: grey;
                &.edited{
                    color: var(--confirmed)
                }
            }

        }
        .rq-body{
            display: flex;
            height: 40px;
            color: grey;
            font-size: 11px;
            text-transform: capitalize;
            
        }
        .rq-footer{
            display: flex;
            height: 30px;
            font-size: 14px;
            align-items:flex-end;
            &.base{
                justify-content:flex-end;
            }
            .rq-input{
                background: none;
                font-size: 14px;
                border: none;
                outline: none;
                height: 30px;
                width: 40px;
                border-bottom: 2px solid #E0E0E0;
                text-align: right;
                margin-right: 5px;
            }
        }
    }
}

.border{
   border: 1px solid red;
   box-sizing: border-box;
}