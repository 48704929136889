.export-csv-wrapper{
    width: 600px;
    height: 244px;
    display: flex;
    flex-direction: column;
	justify-content: center;
	align-items: center;
   

    .export-csv-button-container{
        width: 100%;
		display: flex;
		flex-direction: row;
		gap: 30px;
		margin-top: 30px;
		justify-content: center;
		margin-bottom: 20px;		
    }
}