.menu-icon,
.service-icon,
.drink-icon {
    width: 30px;
}

.menu-name,
.service-name,
.drink-name {
    // background-color: gold;
    // width: 175px;
    width: 32%;

}

.menu-type,
.service-category,
.drink-type {
    // background-color: blue;
    width: 16%;
}

.menu-category,
.service-override,
.drink-category {
    // background-color: forestgreen;
    width: 16%;
}

.menu-price,
.service-price,
.drink-price {
    // background-color: gold;
    width: 16%;
}

.menu-action,
.service-action,
.drink-action {
    // background-color: red;
    width: 68px;
}

.button-cell {
    border-radius: 0px 10px 10px 0px;
    text-align: end;
    padding-right: 10px;
    box-sizing: border-box;

}

.row-action-icon {

    height: 24px;
    width: 24px;
    border-radius: 50px;
    margin-left: 5px;
}

.item-image-wrappers {
    // background-color: red;
    background: #F09E9B 0% 0% no-repeat padding-box;
    width: 100%;
    padding: 3px;
    border-radius: 5px;

    &.veg {
        background: #A9CE7B 0% 0% no-repeat padding-box;
    }

}

.fa-bone {
    color: var(--meat);
}