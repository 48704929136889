.booking-side-modal-view-container {
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	margin: 8px;
	gap: 16px;
	padding: 16px;
	border: 1px solid #e7e7e7;

	@media (max-width: 768px) {
		padding: 8px;
		gap: 8px;
		margin: 0;
	}

	.booking-smv-timing-container {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		gap: 16px;

		.smv-booking-container {
			display: flex;
			flex-direction: column;
			gap: 8px;

			&.flex {
				flex: 1;
			}

			.smv-time {
				height: 120px;
				width: 120px;
				padding: 8px;
				border-radius: 4px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.time-icon {
					font-weight: 600;
					font-size: 3em;
					color: var(--background-color);
				}

				.time-text-container {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					gap: 4px;

					.time-day {
						font-size: 1.1em;
						color: var(--background-color);
						margin: 0;
					}

					.time-text {
						font-size: 1.72em;
						text-transform: uppercase;
						color: var(--background-color);
						font-weight: 800;
						margin: 0;
					}
				}
			}

			.smv-status {
				width: 120px;
				font-size: 1.2em;
				text-align: center;
				padding: 2px;
				border-radius: 4px;
				text-transform: uppercase;
				color: var(--background-color);
				font-weight: 600;
			}

			.smv-customer {
				font-size: 1.64em;
				font-weight: 600;
				color: var(--text-dark-grey);
			}

			.smv-bookingId {
				font-weight: 500;
				font-size: 1.2em;
				color: var(--text-midgray);
			}

			.smv-booking-details-container {
				display: flex;
				flex-wrap: wrap;
				gap: 8px;
				align-items: flex-start;
				justify-content: space-between;

				.smv-details {
					flex: 1;
					min-width: 100px;
				}

				.details-label {
					font-weight: 500;
					font-size: 1em;
					color: var(--text-midgray);
				}

				.details-value {
					font-weight: 500;
					font-size: 1em;
					color: var(--text-dark-grey);
					text-transform: capitalize;

					i {
						margin-right: 4px;
					}

					b {
						font-size: 1.24em;
					}

				}
			}

			.smv-button {
				align-self: flex-start;
				display: flex;
				align-items: center;
				gap: 8px;
				font-size: 1.2em;
				color: var(--afternoon);
				cursor: pointer;
				font-style: italic;
				padding: 4px 0;
				transition: all 200ms ease-in-out;

				span {
					text-decoration: underline;
				}

				&:hover {
					transform: scale(1.05)
				}
			}
		}
	}

	.rc-accordion-card {
		border: 1px solid var(--text-light-grey);
		border-radius: 4px;

		.rc-accordion-toggle {
			display: flex;
			align-items: flex-start;
			cursor: pointer;
			justify-content: space-between;
			background-color: var(--background-color);
			transition: all 0.2s ease-in-out;
			padding: 12px;

			.rc-accordion-title {
				flex: 1;
				font-weight: 500;
				font-size: 1.2em;
				margin-bottom: 0;
				color: var(--text-dark-grey);
				transition: all 0.3s ease-in-out;
			}

			.rc-accordion-icon {
				margin-top: 2px;
				color: var(--text-dark-grey);
				transition: all 0.3s ease-in-out;
				font-size: 1.1em;
			}

			&.active {
				background-color: var(--afternoon);

				.rc-accordion-title {
					color: var(--background-color);
				}

				.rc-accordion-icon {
					transform: rotate(180deg);
					color: var(--background-color);
				}
			}
		}

		.rc-collapse {
			position: relative;
			height: 0;
			transition: height 0.35s ease;

			&.show {
				height: auto;
			}

			.rc-accordion-body {
				flex: 1 1 auto;
				min-height: 1px;
				padding: 12px;
			}
		}
	}
}