.loader-wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.7);
	position: absolute;
	z-index: 999;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

// pac man
.circle {
	position: relative;
	left: -100px;
	width: 0;
	height: 0;
	border: 50px solid #fdd835;
	border-radius: 50%;
	border-right-color: transparent;
	animation: move 5s linear 0s infinite normal forwards;
}

.circle:before {
	content: "";
	position: absolute;
	top: -50px;
	left: -50px;
	width: 0;
	height: 0;
	border: 50px solid #fdd835;
	border-radius: 50%;
	border-right-color: transparent;
	animation: chomp1 0.25s ease-in-out 0s infinite normal forwards;
}

.circle:after {
	content: "";
	position: absolute;
	top: -50px;
	left: -50px;
	width: 0;
	height: 0;
	border: 50px solid #fdd835;
	border-radius: 50%;
	border-right-color: transparent;
	animation: chomp2 0.25s ease-in-out 0s infinite normal forwards;
}

.loader {
	position: relative;
	top: 50px;
	width: 300px;
	height: 0px;
	border-top: 10px dotted black;
	animation: loader_4013 5s ease-in-out 0s infinite normal forwards;
}

.dots {
	position: relative;
	top: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.dot {
	position: relative;
	width: 10px;
	height: 10px;
	margin: 0 10px;
	border-radius: 50%;
	background: #1e88e5;
	animation: dot1 5s linear 0s infinite none normal;
}

.dot:nth-child(1) {
	animation-delay: 0s;
}

.dot:nth-child(2) {
	animation-delay: 0.25s;
}

.dot:nth-child(3) {
	animation-delay: 0.5s;
}

.dot:nth-child(4) {
	animation-delay: 0.75s;
}

.dot:nth-child(5) {
	animation-delay: 1s;
}

.dot:nth-child(6) {
	animation-delay: 1.25s;
}

.dot:nth-child(7) {
	animation-delay: 1.5s;
}

.dot:nth-child(8) {
	animation-delay: 1.75s;
}

.dot:nth-child(9) {
	animation-delay: 1.9s;
}

.dot:nth-child(10) {
	animation-delay: 2.1s;
}

.dots2 {
	position: relative;
	top: 50px;
	left: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.dot2 {
	position: relative;
	left: -10px;
	width: 10px;
	height: 10px;
	margin: 0 10px;
	border-radius: 50%;
	background: #f44336;
	opacity: 0;
	animation: dot2 5s linear 0s infinite none normal;
}

.dot2:nth-child(10) {
	animation-delay: 2.5s;
}

.dot2:nth-child(9) {
	animation-delay: 2.75s;
}

.dot2:nth-child(8) {
	animation-delay: 3s;
}

.dot2:nth-child(7) {
	animation-delay: 3.25s;
}

.dot2:nth-child(6) {
	animation-delay: 3.5s;
}

.dot2:nth-child(5) {
	animation-delay: 3.75s;
}

.dot2:nth-child(4) {
	animation-delay: 4s;
}

.dot2:nth-child(3) {
	animation-delay: 4.25s;
}

.dot2:nth-child(2) {
	animation-delay: 4.5s;
}

.dot2:nth-child(1) {
	animation-delay: 4.6s;
}
/* #F44336;, #1E88E5, #FDD835; */
@keyframes chomp1 {
	0% {
		transform: rotate(0deg);
	}

	50% {
		transform: rotate(45deg);
	}

	100% {
		transform: rotate(0deg);
	}
}

@keyframes chomp2 {
	0% {
		transform: rotate(0deg);
	}

	50% {
		transform: rotate(-45deg);
	}

	100% {
		transform: rotate(0deg);
	}
}

@keyframes move {
	0%,
	100% {
		left: -100px;
	}

	0%,
	48% {
		transform: rotateY(0deg);
	}

	50%,
	100% {
		transform: rotateY(180deg);
	}

	50% {
		left: 100%;
	}
}

@keyframes loader_4013 {
	0%,
	100% {
		border-top: 10px dotted #1e88e5;
	}

	0%,
	48% {
		border-top: 10px dotted #1e88e5;
	}

	50%,
	100% {
		border-top: 10px dotted #f44336;
	}

	50% {
		border-top: 10px dotted #f44336;
	}
}

@keyframes dot1 {
	0%,
	4% {
		background: #1e88e5;
		opacity: 1;
	}

	5%,
	94% {
		background: #f44336;
		opacity: 0;
	}

	95%,
	100% {
		background: #1e88e5;
		opacity: 1;
	}
}

@keyframes dot2 {
	0%,
	4% {
		background: #f44336;
		opacity: 1;
	}

	5%,
	94% {
		opacity: 0;
	}

	95%,
	100% {
		background: #f44336;
		opacity: 1;
	}
}

// bouncing ball
// .loader {
// 	position: relative;
// 	width: 120px;
// 	height: 90px;
// 	margin: 0 auto;
// }

// .loader:before {
// 	content: "";
// 	position: absolute;
// 	bottom: 30px;
// 	left: 50px;
// 	height: 30px;
// 	width: 30px;
// 	border-radius: 50%;
// 	background: #2a9d8f;
// 	animation: loading-bounce 0.5s ease-in-out infinite alternate;
// }

// .loader:after {
// 	content: "";
// 	position: absolute;
// 	right: 0;
// 	top: 0;
// 	height: 7px;
// 	width: 45px;
// 	border-radius: 4px;
// 	box-shadow: 0 5px 0 #f2f2f2, -35px 50px 0 #f2f2f2, -70px 95px 0 #f2f2f2;
// 	animation: loading-step 1s ease-in-out infinite;
// }

// @keyframes loading-bounce {
// 	0% {
// 		transform: scale(1, 0.7);
// 	}

// 	40% {
// 		transform: scale(0.8, 1.2);
// 	}

// 	60% {
// 		transform: scale(1, 1);
// 	}

// 	100% {
// 		bottom: 140px;
// 	}
// }

// @keyframes loading-step {
// 	0% {
// 		box-shadow: 0 10px 0 rgba(0, 0, 0, 0), 0 10px 0 #f2f2f2,
// 			-35px 50px 0 #f2f2f2, -70px 90px 0 #f2f2f2;
// 	}

// 	100% {
// 		box-shadow: 0 10px 0 #f2f2f2, -35px 50px 0 #f2f2f2, -70px 90px 0 #f2f2f2,
// 			-70px 90px 0 rgba(0, 0, 0, 0);
// 	}
// }
