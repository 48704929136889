@import '../../../assets/css/color-variable.scss';
@import '../../../assets/css/size-variable.scss';

/* form select  */
.form-select-wrapper {
	display: flex;
	flex-direction: column;
	margin-bottom: 2px;

	&.venue-number {
		width: 285px;
		height: 62px;
	}

	&.form-sm {
		width: 135px;
		height: 71px;
	}

	&.search {
		width: 135px;
		height: 67px;
	}

	&.venue-role {
		width: 85px;
		height: 50px;
	}

	&.venue-name {
		width: 135px;
		height: 50px;
		// background-color: seagreen;
	}

	.form-select-label {
		font-size: var(--h6);
		color: var(--text-dark-grey);
		margin-bottom: 5px;
		height: 18px;

		&.venue-name,
		&.venue-role {
			display: none;
		}


	}

	.form-select-box {
		border-radius: 5px;
		border: 0;
		outline: 0;
		font-size: var(--h5);

		&:disabled {
			opacity: 0.4;
		}

		&.venue-number {
			width: 85px;
			height: 30px;
		}

		&.form-sm {
			width: 135px;
			height: 30px;
		}

		&.venue-role {
			width: 85px;
			height: 30px;
		}

		&.venue-name {
			width: 135px;
			height: 30px;
		}

		&.search {
			width: 135px;
			height: 30px;
		}


	}

	.form-validation-error {
		color: var(--highlight-red);
		font-size: var(--h7);
		text-align: right;
		height: 15px;
		margin-top: 5px;

		&.venue-name,
		&.venue-role {
			display: none;
		}
	}
}

/* form select ends */

.select-wrapper {
	display: flex;
	flex-direction: column;
}

.validation-error {
	color: #D35A47;
	font-size: 9px;
	height: 7px;
	text-align: right;
	margin-top: 5px;

	&.search-lg {
		display: none;
	}


}

.select-box-wrapper {

	font-size: 13px;
	cursor: pointer;
	border: 0;
	outline: 0;
	text-align: center;
	border-radius: 22px;

	&.modal-sm {
		width: 90px;
		height: 24px;
	}

	&.modal-customer-gender,
	&.modal-employee-gender {
		width: 215px;
		height: 24px;
	}

	&.modal-identification {
		width: 150px;
		height: 24px;
	}

	&.modal-venue-halls {
		width: 70px;
		height: 24px;
	}

	// &.addGender{
	//     width:70px;
	//     height: 24px;
	// }

	&.search-lg {
		width: 100%;
		height: 30px;
		border-radius: 0 !important;
	}

	/* used for nep date filter */
	&.form-sm {
		width: 100%;
		height: 30px;
		border-radius: 0 !important;

	}

	&.addEmployee {
		width: 160px;
		height: 24px;
	}

	&.addMenuServices {
		width: 160px;
		height: 24px;
	}

	&.signupSubscription {
		// width: 50px;
		// height: 28px;
		width: 70px;
		height: 32px;
	}

	// &.inviteEmpRole{
	//     width: 140px;
	//     height: 24px;
	// }
	// &.inviteEmpVenuePermission{
	//     width: 110px;
	//     height: 24px;
	// }
	// &.form-md{
	//     width: 140px;

	// }



	&:hover {
		background-color: #F5F5F5;
		color: #523563;
	}

	option {
		cursor: pointer;
		border-radius: 30px;
		border: 0;
		outline: 0;
		background-color: #EDEDED;
		margin: 15px;
		box-shadow: -2px -2px 4px #FFF, 2px 2px 4px rgba($color: #1c1222, $alpha: 0.2);

	}
}


// select input

.select-input-item {
	width: 100%;
	flex: 1;
	color: #9d9d9d;
	display: flex;
	flex-direction: column;

	.select-input-label {
		color: #9d9d9d;
		font-size: 1em;
		font-weight: 400;
		margin-bottom: 4px;
		white-space: nowrap;
	}

	.custom-select-wrapper {
		border: 1px solid #d8d8d866;
		border-radius: 4px;
		padding: 0 8px;
		background-color: #f1f1fc;

		&.disabled {
			background-color: transparent;
		}

		.custom-select-box-wrapper {
			min-width: 120px;
			font-size: 1em;
			cursor: pointer;
			border: 0;
			outline: 0;
			background-color: transparent;
			width: 100%;
			height: 32px;
			color: #272727;
		}
	}

	.custom-validation-error {
		color: #d35a47;
		font-size: 9px;
	}
}