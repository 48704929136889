.quick-add-booking {
    // border: 1px solid red;
    display: flex;
    width: 100%;
    height: fit-content;
    border-radius: 5px;
    padding: 10px;
    flex-direction: column;
    // margin-bottom: 15px;

    .booking {
        display: grid;
        grid-template-columns: 1fr 70px 70px;
        // border: 1px solid blue;
        font-size: 12px;
        height: fit-content;

        .booking-content {
            display: flex;
            flex-direction: column;

            >p {
                font-size: 12px;
                line-height: 15px;
                margin: 0px;
            }

            &.right>p {
                text-align: right;
            }
        }
    }

    &:hover {
        background: var(--afternoon);
        cursor: pointer;

    }

    &:hover * {
        color: white;
    }

    &.selected {
        background: var(--confirmed);
    }

    &.selected * {
        color: white;
    }
}

.quick-booking-placeholder {
    display: flex;
    width: 100%;
    height: 80%;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    color: var(--highlight-grey);
}


.special-button {
    height: 56px;
    width: 120px;
}

.clearwarn {
    // border: 1px solid red;
    width: 100%;
    text-align: center;
    line-height: 80px;
    border-radius: 10px;
    // color: white;
    // background: var(--highlight-orange);
    color: var(--highlight-orange);

}