@import '../../assets/css/color-variable.scss';

.payment-history-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .payment-history-title {
        text-align: center;
        color: var(--text-dark-grey);
        font-size: 18px;
        padding: 10px 0px;
    }
}